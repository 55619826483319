import * as React from 'react';
import Comment from '../../../sub/comment';
import { Props } from '../../native';
import { NativeTransparencyButton } from '../../parts/native-transparency-button';
import { NativeImage } from '../../parts/native-image';
import { NativeTopLeftLabel } from '../../parts/native-top-left-label';
import Description from '../../../sub/description';
import { NativeTitleWithAnchor } from '../../parts/native-title-with-anchor';
import { NativeLogo } from '../../parts/native-logo';

export const ResultTravelAd = (props: Props) => {
    const { id, adObj, accessibilityId, subvertical } = props;
    const { native } = adObj;

    return (
        <div
            id={id}
            aria-owns={accessibilityId}
            className={`relative flex flex-row p-16 shadow rounded-8 hover:ring focus:ring outline-none ${
                subvertical === 'hotel' ? 'card' : ''
            }`}
        >
            <Comment placement="ResultTravelAd" adObj={adObj} />
            <div
                className={`flex overflow-hidden relative mr-8`}
                style={{
                    flex: 3,
                }}
            >
                <NativeImage
                    native={native}
                    radiusClass={'rounded-8'}
                    aspectClass={'h-full'}
                />
                <NativeTopLeftLabel radiusClass={'rounded-br-8 rounded-tl-8'} />
            </div>

            <div
                className={`flex flex-col my-16 pb-48`}
                style={{
                    flex: 4,
                }}
            >
                <div className={'text-14 s-text-subtle mb-8'}>
                    {native.sponsoredBy}
                </div>
                <NativeTitleWithAnchor
                    className={'font-normal'}
                    native={native}
                    accessibilityId={accessibilityId}
                />
                <Description native={native} className="text-14" />
                <div className={'absolute bottom-8 right-8'}>
                    <NativeLogo native={native} />
                </div>
            </div>
            <NativeTransparencyButton adObj={adObj} />
        </div>
    );
};
