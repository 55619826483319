export function escape(text: string): string;
export function escape(text: number): number;
export function escape(text: undefined): string;
export function escape(text: string | number | undefined) {
    if (!text) {
        return '';
    }

    let newtext = text;
    if (typeof text === 'string') {
        newtext = text
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }
    return newtext;
}

export function isNonEmptyArray(arr: any) {
    return arr && Array.isArray(arr) && arr.length > 0;
}

export function isNonEmptyString(s: any) {
    return s && typeof s === 'string' && s.length > 0;
}

export function formatUrl(url: string) {
    return url.replace(/(^\w+:|^)\/\//, '').replace(/\/.*$/, '');
}
