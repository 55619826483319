import { MessageBus } from '@podium/browser';
import { Event } from '@podium/browser';
import diagnostics from '../utils/diagnostics';

class MessageBusFactory {
    private static instance: MessageBus | null = null;

    public static getInstance(): MessageBus {
        if (
            typeof window === 'undefined' ||
            typeof MessageBus === 'undefined'
        ) {
            throw new Error(
                "Cannot instantiate MessageBus. The environment doesn't support it.",
            );
        }

        if (!this.instance) {
            this.instance = new MessageBus();
        }

        return this.instance;
    }
}

export function peek(channel: string, topic: string): Event | void {
    try {
        const messageBus = MessageBusFactory.getInstance();
        return messageBus.peek(channel, topic);
    } catch (e) {
        // handle the error, possibly logging it
        console.error(e);
    }
}

export function unsubscribe(
    channel: string,
    topic: string,
    callback: (event: Event) => void,
) {
    try {
        const messageBus = MessageBusFactory.getInstance();
        messageBus.unsubscribe(channel, topic, callback);
    } catch (e) {
        // handle the error, possibly logging it
        console.error(e);
    }
}

export function subscribe(
    channel: string,
    topic: string,
    callback: (event: Event) => void,
) {
    try {
        const messageBus = MessageBusFactory.getInstance();
        messageBus.subscribe(channel, topic, callback);

        const eventFromBeforeSubscription = messageBus.peek(channel, topic);
        if (eventFromBeforeSubscription) {
            diagnostics(
                'Message waiting on app init %c%s - %s',
                'font-weight: bold;',
                eventFromBeforeSubscription.channel,
                eventFromBeforeSubscription.topic,
                eventFromBeforeSubscription.payload,
            );
            callback(eventFromBeforeSubscription);
        }
    } catch (e) {
        // handle the error, possibly logging it
        console.error(e);
    }
}

export function publish(channel: string, topic: string, payload?: unknown) {
    try {
        const messageBus = MessageBusFactory.getInstance();
        diagnostics(
            'Publishing message %c%s - %s',
            'font-weight: bold;',
            channel,
            topic,
            payload,
        );
        messageBus.publish(channel, topic, payload);
    } catch (e) {
        // handle the error, possibly logging it
        console.error(e);
    }
}
