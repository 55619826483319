export function getDeviceType() {
    if (typeof window === 'undefined') return undefined;
    try {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.includes('tablet') || ua.includes('ipad')) {
            return 'tablet';
        } else if (ua.includes('mobi')) {
            return 'mobile';
        }
        return 'desktop';
    } catch (e) {
        return undefined;
    }
}
