import { TImprovedContentMarketing } from '../../../../shared/types/contentMarketing';
import * as React from 'react';
import { getConfig } from '../../../config';
import { useCallback, useMemo } from 'react';
import { useWebViewData } from '../useWebViewData';

function getPercentage(n: number, size: number) {
    return (n / size) * 100;
}

export type Props = {
    contentMarketing: TImprovedContentMarketing;
    alt: string;
    isMultipleAds: boolean;
    hasImageRadius?: boolean;
    children?: React.ReactNode;
};
const ImageWithFocus = ({
    contentMarketing,
    alt,
    isMultipleAds,
    hasImageRadius,
    children,
}: Props) => {
    const imageRef = React.useRef(null);
    const { deviceType } = getConfig();

    const { imageData } = contentMarketing;
    const { isLandscapeMode, isDarkMode } = useWebViewData();

    const objectPosition = useMemo(() => {
        if (imageData?.aoi?.focus) {
            const y = getPercentage(imageData.aoi.focus.y, imageData.height);
            const x = getPercentage(imageData.aoi.focus.x, imageData.width);
            return `${x}% ${y}%`;
        } else {
            return '50% 50%';
        }
    }, [imageData]);

    const cN = useMemo(() => {
        const isMobile = deviceType === 'mobile';
        const aspectRatio =
            isMultipleAds || isMobile ? 'aspect-3/2' : 'aspect-16/9';
        const radius = hasImageRadius && !isDarkMode ? 'rounded-8' : '';
        return {
            aspectRatio,
            radius,
        };
    }, [deviceType, isMultipleAds, hasImageRadius, isDarkMode]);

    const renderImage = useCallback(() => {
        return (
            <div
                className={cN.aspectRatio}
                style={isLandscapeMode ? { paddingBottom: '20%' } : {}}
            >
                <img
                    loading={'lazy'}
                    alt={alt}
                    src={imageData?.url}
                    className={`h-full w-full object-cover ${cN.radius}`}
                    style={{
                        objectPosition,
                    }}
                />
            </div>
        );
    }, [
        cN.aspectRatio,
        cN.radius,
        isLandscapeMode,
        alt,
        imageData?.url,
        objectPosition,
    ]);

    return (
        <div
            ref={imageRef}
            className={'overflow-hidden relative h-full w-full'}
        >
            {renderImage()}
            {children}
        </div>
    );
};

export default ImageWithFocus;
