import { useEffect, useState } from 'react';
import { getConfig } from '../config';
import { MessageBus } from '@podium/browser';

import {
    AdObj,
    AdError,
    Status,
    DefineTagOptions,
    Keywords,
    Finn,
    BannerGlobal,
    BannerAdObj,
} from '../../shared/types/ast';

import { Placement, Size as NRMSize } from '../../shared/types/reklameMaster';
import { inview } from '../utils/lazy';
import { displayAdsLog } from '../utils/debug';
import {
    debouncedLoadTagsSafe,
    defineTag,
    onAdAvailable,
    onAdBadRequest,
    onAdLoaded,
    onAdNoBid,
    onAdRequested,
    pushToAnqSafe,
    showTag,
} from '../podlet/ast';
import useCycleAd from './cycle-ad';

let messageBus = undefined;

if (typeof window !== 'undefined') {
    messageBus = new MessageBus();
}
function processedNrmSizes(
    nrmSizes: NRMSize[],
    skyscraperOnly: boolean = false,
) {
    function arrayEquals(a: number[], b: number[]) {
        return (
            Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index])
        );
    }
    const sizes = nrmSizes.map((size: NRMSize) => [size.width, size.height]);
    if (skyscraperOnly) {
        return sizes.filter((size) => !arrayEquals(size, [180, 700]));
    }
    return sizes;
}

export function tag(
    placementId: string,
    targetId: string,
    skyscraperOnly?: boolean,
): DefineTagOptions | undefined {
    displayAdsLog(
        ` tag() placementId ${placementId} targetId ${targetId} skyscraperOnly ${skyscraperOnly}`,
    );
    let config = getConfig();

    let nrmTag;
    const placementConfig = config?.placements?.find(
        (p: Placement) => p.placementId === placementId,
    );

    displayAdsLog(`placementConfig `, placementConfig);

    nrmTag = !placementConfig?.inventoryCode
        ? undefined //a way of filtering nulls
        : {
              targetId,
              sizes: processedNrmSizes(placementConfig?.sizes, skyscraperOnly),
              keywords: placementConfig?.keywords as Keywords,
              invCode: placementConfig?.inventoryCode,
              allowedFormats: placementConfig?.allowedFormats,
              native: placementConfig?.native,
          };
    displayAdsLog(`nrmTag `, nrmTag);

    return nrmTag as DefineTagOptions;
}

export function getSizeFromAdObj(adObj: BannerAdObj | undefined): {
    height: number;
    width: number;
} | null {
    return adObj?.height && adObj?.width
        ? {
              height: adObj.height,
              width: adObj.width,
          }
        : null;
}

export function useAd(
    placementId: string,
    skyscraperOnly?: boolean,
    customTag?: DefineTagOptions,
) {
    if (!messageBus && typeof window !== 'undefined') {
        messageBus = new MessageBus();
    }

    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState<Status>('requested');
    const [adObj2, setAdObj] = useState<AdObj>();
    const [error, setError] = useState<AdError>();
    const targetId = useCycleAd(placementId);

    const tagConfig = customTag || tag(placementId, targetId, skyscraperOnly);
    displayAdsLog(`customTag `, tagConfig);
    // useAdSizeCache({ placementId, adObj: adObj2, status });
    const sizes = tagConfig?.sizes;

    useEffect(() => {
        if (placementId && typeof window === 'object') {
            displayAdsLog(`useAd: window === object`);

            window.FINN = window.FINN || ({} as Finn);
            window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
            window.FINN.banner.queue = window.FINN.banner.queue || [];
            window.FINN.banner.queue.push(() => {
                if (window.apntag) {
                    onAdRequested(targetId, () => {
                        setStatus('requested');
                    });

                    onAdAvailable(targetId, (adObj) => {
                        setAdObj(adObj);

                        setLoaded(true);
                        setStatus('available');
                    });

                    onAdLoaded(targetId, (adObj) => {
                        setAdObj(adObj);
                        setStatus('loaded');
                    });

                    onAdNoBid(targetId, () => {
                        setLoaded(true);
                        setStatus('failed');
                    });

                    onAdBadRequest(targetId, (error) => {
                        setLoaded(true);
                        setError(error);
                        setStatus('failed');
                    });
                }

                if (tagConfig) {
                    displayAdsLog(`debouncedLoadTagsSafe FROM ad.ts`);
                    pushToAnqSafe(() => {
                        defineTag(tagConfig);
                    });
                    debouncedLoadTagsSafe(500);
                }
            });
        } else {
            setLoaded(true);
            setStatus('failed');
        }

        return function cleanup() {
            setAdObj(undefined);
            if (window.apntag && window.apntag.anq) {
                window.apntag.anq.push(() => {
                    if (window.apntag) {
                        window.apntag.offEvent('adRequested', targetId);
                        window.apntag.offEvent('adAvailable', targetId);
                        window.apntag.offEvent('adLoaded', targetId);
                        window.apntag.offEvent('adNoBid', targetId);
                        window.apntag.offEvent('adBadRequest', targetId);
                    }
                });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetId]); // We only want this to run when targetId changes

    return {
        adObj: adObj2,
        loaded,
        error,
        status,
        targetId,
        sizes,
    };
}

export function useShowTag(id: string, proximity = 100, threshold = 0.0) {
    useEffect(() => {
        let cleanup = () => {};

        const loadElement = (ele: Element) => {
            const tagId = ele.getAttribute('id');
            if (tagId) {
                // Loads search result ad
                pushToAnqSafe(() => {
                    showTag(tagId);
                });
            }
        };

        pushToAnqSafe(() => {
            const element = document.getElementById(id);
            if (element) {
                cleanup = inview(element, loadElement, proximity, threshold);
            }
        });

        return cleanup;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]); // We only want this to run when id changes
    return {};
}
