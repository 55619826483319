import * as React from 'react';
import { Native } from '../../../shared/types/ast';
import { escape } from '../../utils/component/helpers';

type Props = {
    native: Native;
    placement?: string;
    className?: string;
    style?: React.CSSProperties;
    width?: number;
    height?: number;
};

function getImage(
    native?: Native,
    className?: string,
    style?: Object,
    width?: number,
    height?: number,
) {
    let logoUrl;
    if (native && native.icon && native.icon.url) {
        // v3
        logoUrl = native.icon.url;
    } else if (native && native.iconImgUrl) {
        // v2
        logoUrl = native.iconImgUrl;
    } else {
        return null;
    }

    logoUrl = escape(logoUrl);
    const altText = escape(native.sponsoredBy ? native.sponsoredBy : '');

    return (
        <img
            src={logoUrl}
            className={className}
            style={style}
            alt={altText}
            width={width ? width : ''}
            height={height ? height : ''}
        />
    );
}

const Logo = ({
    native,
    placement,
    className,
    style,
    width,
    height,
}: Props) => {
    const containImageStyle = { objectFit: 'contain', alignSelf: 'flex-end' };
    const updatedStyle = { ...containImageStyle, ...style };
    const ICON = getImage(native, className, updatedStyle, width, height);
    if (ICON && placement === 'new-netboard') {
        return (
            <div className="overflowify">
                <div
                    className="fright result-item-logo-container mls mts"
                    style={{ height: '60px' }}
                >
                    {ICON}
                </div>
            </div>
        );
    } else if (ICON && placement === 'netboard') {
        return <div className="native-add__media__logo">{ICON}</div>;
    }

    return ICON;
};

export default Logo;
