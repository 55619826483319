import SponsorHeader from '../../../components/sponsor-header';
import * as React from 'react';
import VerticalBlock from '../../../blocks/vertical-block';
import FabricAnchorCard from '../../../components/fabric-anchor-card';
import { TImprovedContentMarketing } from '../../../../../../shared/types/contentMarketing';

const RealEstateResultSingle = ({
    contentMarketing,
    isMultipleAds = false,
}: {
    contentMarketing: TImprovedContentMarketing;
    isMultipleAds?: boolean;
}) => {
    return (
        <div>
            <SponsorHeader
                contentMarketing={contentMarketing}
                className={'pr-0'}
            />
            <FabricAnchorCard contentMarketing={contentMarketing}>
                <VerticalBlock
                    isMultipleAds={isMultipleAds}
                    contentMarketing={contentMarketing}
                />
            </FabricAnchorCard>
        </div>
    );
};

export default RealEstateResultSingle;
