let isDebugMode: boolean | undefined;

export function hasDisplayAdsDebugQuery() {
    try {
        const queryParams: any = new Proxy(
            new URLSearchParams(window.location.search),
            {
                get: (searchParams, prop) => searchParams.get(prop as string),
            },
        );

        return queryParams.displayads_debug;
    } catch (e) {
        return false;
    }
}

if (typeof window !== 'undefined' && typeof isDebugMode === 'undefined') {
    isDebugMode = hasDisplayAdsDebugQuery();
}

export function displayAdsLog(...args) {
    const prefix = '[displayads-podlet] ';
    if (isDebugMode) {
        // && hasDisplayAdsDebugQuery()
        console.warn(prefix, ...args);
    }
}
