import { logCreativeClick } from '../../utils/logger';
import * as React from 'react';
import { TImprovedContentMarketing } from '../../../shared/types/contentMarketing';
import { usePulseLoadAndClick } from './usePulseLoadAndClick';
import { getConfig } from '../../config';

type Props = {
    placementId: string;
    multiAdIndex?: number;
    contentMarketing: TImprovedContentMarketing;
    children: React.ReactNode;
    isMultipleAds?: boolean;
};
const { platform } = getConfig();

export const ContentMarketingElement = ({
    placementId,
    contentMarketing,
    children,
    isMultipleAds = false,
    multiAdIndex = undefined,
}: Props) => {
    const disablePulseTracking = platform === 'ios' || platform === 'android';
    const creativeClickObject = usePulseLoadAndClick({
        placementId,
        contentMarketing,
        isMultipleAds,
        multiAdIndex,
        disablePulseTracking,
    });

    const id = creativeClickObject?.elementId || placementId;

    const handleClickEvent = (e) => {
        if (disablePulseTracking) return;
        if (contentMarketing?.videoUrl) {
            if (e.target.id === `cm-title-${contentMarketing.id}`) {
                // Call your function here
                logCreativeClick(creativeClickObject);
            }
        } else {
            logCreativeClick(creativeClickObject);
        }
    };

    return (
        <>
            <article
                onClick={handleClickEvent}
                className={isMultipleAds ? 'w-full h-full' : 'col-span-full'}
                id={id}
            >
                {children}
            </article>
        </>
    );
};
