import * as React from 'react';
import { Native } from '../../../shared/types/ast';
import { escape } from '../../utils/component/helpers';

type Props = {
    native: Native;
    h?: number;
    id?: string;
    className?: string;
    style?: React.CSSProperties;
};

const Title = ({ native, h, id, className, style }: Props) => {
    let title = escape(native.title);

    if (h === 2) {
        return (
            <h2
                id={id}
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html: title }}
            />
        );
    } else if (h === 3) {
        return (
            <h3
                id={id}
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html: title }}
            />
        );
    } else {
        return (
            <div
                id={id}
                className={className}
                style={style}
                dangerouslySetInnerHTML={{ __html: title }}
            />
        );
    }
};

export default Title;
