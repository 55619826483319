import { Clickable } from '@warp-ds/react';
import * as React from 'react';

export const NativeTitleWithAnchor = ({
    accessibilityId,
    native,
    className = 'font-normal',
}) => {
    return (
        <h2
            id={accessibilityId}
            className={'ads-native-anchor-title text-16 mb-0'}
        >
            <Clickable
                href={native.clickUrl}
                title={native.title}
                rel="noreferrer"
                target="_blank"
                className={'da-no-underline'}
            >
                <span style={{ color: '#464646' }} className={className}>
                    {native.title}
                </span>
            </Clickable>
        </h2>
    );
};
