import { Props } from '../../native';
import Comment from '../../../sub/comment';
import * as React from 'react';
import { NativeTransparencyButton } from '../../parts/native-transparency-button';
import { NativeTopLeftLabel } from '../../parts/native-top-left-label';
import { NativeTitleWithAnchor } from '../../parts/native-title-with-anchor';
import Description from '../../../sub/description';
import { NativeLogo } from '../../parts/native-logo';
import { NativeImage } from '../../parts/native-image';

export function ObjectAd(props: Props) {
    const { id, adObj, accessibilityId } = props;
    const { native } = adObj;

    return (
        <div
            id={id}
            aria-owns={accessibilityId}
            className={`relative flex flex-col rounded-8 hover:s-bg-hover p-8`}
        >
            <Comment placement="ObjectAd" adObj={adObj} />
            <div aria-owns={`result-item-heading-${adObj.targetId}`} />
            <div
                className={`flex relative relative rounded-8 h-full w-full overflow-hidden`}
            >
                <NativeImage
                    native={native}
                    radiusClass={'rounded-8'}
                    aspectClass={
                        'h-full w-full aspect-4/3 overflow-hidden relative'
                    }
                />
                <NativeTransparencyButton adObj={adObj} />
                <NativeTopLeftLabel text={'Annonse'} />
            </div>
            <div className={`flex flex-col p-8 pb-64`}>
                <div className={'text-14 s-text-subtle'}>
                    {native.sponsoredBy}
                </div>
                <NativeTitleWithAnchor
                    accessibilityId={accessibilityId}
                    native={native}
                    className={'font-bold'}
                />
                <Description native={native} className="text-14" />
            </div>
            <div className={`absolute bottom-8 right-8`}>
                <NativeLogo native={native} />
            </div>
        </div>
    );
}
