import { trackEvent } from '@finn-no/pulse-sdk';
import { inscreen } from './lazy';
import { Subvertical, Vertical } from '../../shared/types/ast';
import { TrackerData } from '@finn-no/pulse-sdk/dist/types';
import { TPlatform } from '../../shared/types/podlet';
import { getConfig } from '../config';
import { displayAdsLog } from './debug';

interface CreativeProps extends TrackerData {
    creativeId: string;
    elementId: string;
    verticalName: Vertical;
    platform?: TPlatform;
    subVertical?: Subvertical;
    elementType: string;
    adFormat: 'Split-view' | 'Single-view' | 'Carousel';
}
interface CreativeLoadViewProps extends CreativeProps {}

export interface CreativeClickProps extends CreativeProps {
    targetId: string;
    provider: string;
}

function getObjectData({
    creativeId,
    objectType,
    adType,
    elementType,
    elementId,
    adFormat,
}): TrackerData['object'] {
    return {
        id: creativeId,
        type: objectType,
        adType,
        adFormat,
        elementType,
        adPosition: elementId,
        adCreative: creativeId,
    };
}
export function countObjectPageBannerLoad(
    id: string,
    placementId: string,
    type: 'Error' | 'Load' | 'NoBid' | 'Available' | 'Requested',
    creativeId?: string,
) {
    const { pageType, vertical, subvertical } = getConfig();
    if (placementId === 'board_1' && pageType === 'object') {
        displayAdsLog(
            `countObjectPageBannerLoad(${id}, ${placementId}, ${type}, ${creativeId})`,
        );
        trackEvent({
            type: 'View',
            name: `Object DisplayAd ${type}`,
            object: {
                adType: 'Banner',
                id: id,
                adCreative: creativeId || '',
                type: 'DisplayAd',
                adPosition: placementId,
            },
            vertical: {
                name: vertical,
                subVertical: subvertical,
            },
        });
    }
}

export function logCreativeLoad(props: CreativeLoadViewProps) {
    const {
        creativeId,
        elementId,
        verticalName,
        subVertical,
        adType = 'Content',
        name = 'Display ad',
        objectType = 'DisplayAd',
        elementType,
        adFormat = 'Single-view',
    } = props;

    trackEvent({
        type: 'Load',
        name,
        object: getObjectData({
            creativeId,
            objectType,
            adType,
            elementType,
            elementId,
            adFormat,
        }),
        vertical: {
            name: verticalName,
            subVertical,
        },
    });
    const element = document.getElementById(elementId);
    if (element) {
        observeCreative(element, props);
    }
}

function logCreativeView(props: CreativeLoadViewProps) {
    const {
        creativeId,
        elementId,
        verticalName,
        subVertical,
        adType = 'Content',
        name = 'Display ad',
        objectType = 'DisplayAd',
        elementType,
        adFormat = 'Single-view',
    } = props;

    trackEvent({
        type: 'View',
        name,
        object: getObjectData({
            creativeId,
            objectType,
            adType,
            elementType,
            elementId,
            adFormat,
        }),
        vertical: {
            name: verticalName,
            subVertical,
        },
    });
}

export function logCreativeClick(props: CreativeClickProps) {
    const {
        creativeId,
        elementId,
        verticalName,
        subVertical,
        adType = 'Content',
        name = 'Display ad',
        objectType = 'DisplayAd',
        targetId,
        provider,
        elementType,
        adFormat = 'Single-view',
    } = props;

    trackEvent({
        type: 'Click',
        intent: 'Open',
        name,
        object: getObjectData({
            creativeId,
            objectType,
            adType,
            elementType,
            elementId,
            adFormat,
        }),
        vertical: {
            name: verticalName,
            subVertical,
        },
        target: {
            id: targetId,
            referralCommission: false,
            type: 'ExternalContent',
            provider: provider,
        },
    });
}

function observeCreative(ele: HTMLElement, props: CreativeProps) {
    inscreen(ele, () => logCreativeView(props), {
        timeout: 1000,
    });
}

function getVideoObject(
    creativeId: string,
    durationSeen: number,
    durationInVideo: number,
    intent: string,
    vertical,
): TrackerData['object'] {
    return {
        assetType: 'Video',
        autoplay: true,
        intent,
        pcFormat: 'adfree',
        tags: ['content-marketing', 'video-ad', `${vertical}-ad`],
        duration: Math.ceil(durationInVideo) || 0,
        position: Math.ceil(durationSeen) || 0,
        embed: false,
        fullscreen: false,
        muted: true,
        streamType: 'vod',
        name: 'contentMarketingVideo',
        category: '',
        adFormat: 'videocontent',
        // @ts-ignore
        type: 'MediaAsset',
        id: creativeId,
    };
}

export function logVideoLoad(
    creativeId,
    verticalName,
    subVertical,
    durationSeen,
    durationInVideo,
) {
    const intent = 'Load';
    trackEvent({
        type: 'Engagement',
        intent,
        name: 'Video Ad',
        object: getVideoObject(
            creativeId,
            durationSeen,
            durationInVideo,
            intent,
            verticalName,
        ),
        vertical: {
            name: verticalName,
            subVertical,
        },
    });
}
export function logVideoPlay(
    creativeId,
    verticalName,
    subVertical,
    durationSeen,
    durationInVideo,
) {
    const intent = 'Play';
    trackEvent({
        type: 'Engagement',
        intent,
        name: 'Video Ad',
        object: getVideoObject(
            creativeId,
            durationSeen,
            durationInVideo,
            intent,
            verticalName,
        ),
        vertical: {
            name: verticalName,
            subVertical,
        },
    });
}

export function logVideoPause(
    creativeId,
    verticalName,
    subVertical,
    durationSeen,
    durationInVideo,
) {
    const intent = 'Pause';
    trackEvent({
        type: 'Engagement',
        intent,
        name: 'Video Ad',
        object: getVideoObject(
            creativeId,
            durationSeen,
            durationInVideo,
            intent,
            verticalName,
        ),
        vertical: {
            name: verticalName,
            subVertical,
        },
    });
}
