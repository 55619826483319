import * as React from 'react';
const FinnLogoSvg = () => {
    return (
        <svg
            width="23"
            height="12"
            viewBox="0 0 23 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.1158 11.0115V0.98854C22.1158 0.790857 21.9562 0.630005 21.76 0.630005H11.8155C11.6193 0.630005 11.4596 0.790857 11.4596 0.98854V11.3699H21.76C21.9562 11.3699 22.1158 11.2092 22.1158 11.0115Z"
                fill="#71717A"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.50193 0.630005H0.355779C0.159576 0.630005 0 0.790857 0 0.98854V11.0115C0 11.2092 0.159576 11.37 0.355779 11.37H10.6596V7.8416C10.6596 3.8651 7.44868 0.630005 3.50193 0.630005Z"
                fill="#3F3F46"
            />
        </svg>
    );
};
export default FinnLogoSvg;
