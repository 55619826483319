import { Props } from '../../native';
import Comment from '../../../sub/comment';
import * as React from 'react';
import { NativeTransparencyButton } from '../../parts/native-transparency-button';
import { NativeLogo } from '../../parts/native-logo';
import Description from '../../../sub/description';
import { NativeTopLeftLabel } from '../../parts/native-top-left-label';
import { NativeTitleWithAnchor } from '../../parts/native-title-with-anchor';
import { getImageSizes, NativeImage } from '../../parts/native-image';

export function ResultBapAd(props: Props) {
    const { id, adObj, grid, accessibilityId } = props;
    const { native } = adObj;
    const list = !grid;

    return (
        <div
            id={id}
            className={`relative overflow-hidden rounded-8 ${
                list ? 'p-10 -m-8' : ''
            }`}
            aria-owns={accessibilityId}
        >
            <Comment placement="ResultBapAd" adObj={adObj} />
            <div className={`flex ${list ? 'flex-row' : 'flex-col'} gap-8`}>
                <div
                    className={`relative`}
                    style={{ width: list ? '31.5%' : '100%' }}
                >
                    <NativeImage
                        aspectClass={`${list ? 'aspect-3/2' : 'aspect-4/3'}`}
                        radiusClass={'rounded-8'}
                        imageSizes={getImageSizes(
                            list ? 'list' : 'grid',
                            'bap',
                        )}
                        native={native}
                    />
                    <NativeTopLeftLabel
                        radiusClass={'rounded-br-8 rounded-tl-8'}
                    />
                </div>
                <div
                    className={`font-normal px-8 ${list ? 'w-3/5' : 'w-full'}`}
                >
                    <div
                        className={`flex flex-row justify-between text-12 items-center`}
                    >
                        <div
                            className={`text-14 s-text-subtle flex ${
                                list ? 'flex-row mb-4' : 'flex-col'
                            } gap-8`}
                        >
                            {native.sponsoredBy}
                        </div>
                    </div>
                    <div
                        className={`flex flex-row items-end gap-4 ${
                            list ? '' : 'pb-64'
                        }`}
                    >
                        <div className={`flex flex-col`}>
                            <NativeTitleWithAnchor
                                className={'text-16 font-normal'}
                                native={native}
                                accessibilityId={accessibilityId}
                            />

                            <Description native={native} className="text-12" />
                        </div>
                        <div className={'absolute bottom-8 right-8'}>
                            <NativeLogo native={native} />
                        </div>
                    </div>
                </div>
                <NativeTransparencyButton adObj={adObj} />
            </div>
        </div>
    );
}
