import { MutableRefObject, useEffect, useState } from 'react';
import { MessageBus } from '@podium/browser';
import {
    NativeAdObj,
    AdError,
    PageType,
    DeviceType,
    DefineTagOptions,
    Status,
    Keywords,
} from '../../shared/types/ast';
import diagnostics from '../utils/diagnostics';
import { inscreen } from '../utils/lazy';
import { publish } from '../podlet/events';
import { displayAdsLog } from '../utils/debug';
import { isNative } from '../utils/ast';
import { defineTag, loadAstTags } from '../podlet/ast';

export function warnIfRemoved(target: HTMLElement) {
    if ('MutationObserver' in window) {
        const observer = new MutationObserver(function (mutations) {
            const targetRemoved = mutations.some(function (mutation) {
                return Array.from(mutation.removedNodes).indexOf(target) !== -1;
            });

            if (targetRemoved) {
                diagnostics.warn(
                    `Native ad container ${target.id} removed from DOM. Impressions and clicks will not be registred`,
                );
            }
        });
        if (target.parentNode) {
            observer.observe(target.parentNode, {
                childList: true,
            });
        }
        const cleanup = () => {
            observer.disconnect();
        };
        return cleanup;
    }
    return () => {
        diagnostics.info('MutationObserver not available');
    };
}

const bannerDeviceType = (deviceType: DeviceType) => {
    switch (deviceType) {
        case 'desktop':
            return 'wde';
        case 'tablet':
            return 'wtb';
        case 'mobile':
            return 'wph';
    }
};

export const loadElement = (tagId: string) => {
    if (tagId) {
        if (window.apntag) {
            window.apntag.anq.push(() => {
                if (window.apntag) {
                    window.apntag.showTag(tagId);
                }
            });
        }
    }
};

function recircIndex(id: string) {
    const lastNumber = /(\d+)(?!.*\d)/;
    const numberAsMatch = id.match(lastNumber);
    if (numberAsMatch) {
        const numberAsString = numberAsMatch[0];
        const parsedNumber = parseInt(numberAsString, 10);
        return parsedNumber > 3 ? 4 : parsedNumber;
    }
    return 0;
}

export const NATIVE_AD_CHANNEL = 'displayads-horseshoe-podlet';
export const NATIVE_AD_TOPICS = {
    IN_SCREEN: 'ad-in-screen-event',
    CLICK: 'ad-click-event',
};

function messageBusInScreenHandler(_, targetId) {
    if (window.apntag) {
        window.apntag.anq.push(() => {
            if (window.apntag) {
                // Triggers adLoaded
                window.apntag.showTag(targetId);
            }
        });
    }
}

function messageBusClickHandler(_, adObj) {
    if (window.apntag && adObj) {
        // Hack from data-podlet-server because attachClickTrackers
        // tries to document.getElementById, which fails since
        // data-podlet-server is inside a web component
        const tempDiv = document.createElement('div');
        const tempDivId = 'temp-' + adObj.targetId;
        tempDiv.setAttribute('id', tempDivId);
        document.body.appendChild(tempDiv);
        window.apntag.attachClickTrackers(adObj, tempDivId);
        tempDiv.click();
        document.body.removeChild(tempDiv);
    }
}

export function useNativeAd(
    instanceId: string,
    pageType: PageType,
    deviceType: DeviceType,
    keywords: { [key: string]: string },
    messageBus?: MessageBus,
) {
    const [loaded, setLoaded] = useState(false);
    const [data, setData] = useState<NativeAdObj>();
    const [error, setError] = useState<AdError>();

    useEffect(() => {
        if (pageType && deviceType && window.apntag && window.apntag.anq) {
            const index = recircIndex(instanceId);
            const devicePrefix = bannerDeviceType(deviceType);
            const formatPrefix = deviceType === 'mobile' ? 'board' : 'netboard';
            const categoryKeywords = Object.entries(keywords).reduce(
                (previousValue, [key, value]) => {
                    previousValue[`no-sno-finn-${key}`] = [value];
                    return previousValue;
                },
                {} as Keywords,
            );

            window.apntag.anq.push(() => {
                const streamTag: DefineTagOptions = {
                    invCode: `no-finn-${devicePrefix}-${pageType}_recirc_${index}`,
                    sizes: [[1, 1]],
                    allowedFormats: ['native'],
                    targetId: instanceId,
                    keywords: {
                        'no-sno-finn-section': ['bap'],
                        'no-sno-finn-subsection': ['bap_forsale'],
                        'no-sno-adformat': [`${formatPrefix}_recirc`],
                        ...categoryKeywords,
                    },
                    disablePsa: true,
                    member: 9700,
                };
                if (window.apntag) {
                    // const pageOpts = setExternalUid({});
                    // setPageOpts(pageOpts)
                    displayAdsLog('debouncedLoadTagsSafe FROM native.ts');
                    defineTag(streamTag);
                    loadAstTags();

                    window.apntag.onEvent(
                        'adAvailable',
                        instanceId,
                        (adObj) => {
                            if (
                                adObj &&
                                adObj.adType === 'native' &&
                                adObj.source === 'rtb'
                            ) {
                                setData(adObj);

                                messageBus.subscribe(
                                    NATIVE_AD_CHANNEL,
                                    NATIVE_AD_TOPICS.IN_SCREEN,
                                    (e) =>
                                        messageBusInScreenHandler(
                                            e,
                                            adObj.targetId,
                                        ),
                                );

                                messageBus.subscribe(
                                    NATIVE_AD_CHANNEL,
                                    NATIVE_AD_TOPICS.CLICK,
                                    (e) => messageBusClickHandler(e, adObj),
                                );
                            }
                            setLoaded(true);
                        },
                    );

                    window.apntag.onEvent('adLoaded', instanceId, (adObj) => {
                        if (isNative(adObj)) {
                            if (window.apntag) {
                                window.apntag.fireImpressionTrackers(adObj);
                            }
                        }
                    });

                    window.apntag.onEvent('adNoBid', instanceId, () => {
                        setLoaded(true);
                    });

                    window.apntag.onEvent(
                        'adBadRequest',
                        instanceId,
                        (error) => {
                            displayAdsLog('adBadRequest');
                            setLoaded(true);
                            setError(error);
                        },
                    );
                }
            });
        } else {
            setLoaded(true);
        }

        return () => {
            if (messageBus && data) {
                messageBus.unsubscribe(
                    NATIVE_AD_CHANNEL,
                    NATIVE_AD_TOPICS.IN_SCREEN,
                    (e) => messageBusInScreenHandler(e, data?.targetId),
                );
                messageBus.unsubscribe(
                    NATIVE_AD_CHANNEL,
                    NATIVE_AD_TOPICS.CLICK,
                    (e) => messageBusClickHandler(e, data),
                );
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // We only want this to run once

    return { data, loaded, error };
}

export function useLoadNativeAd(
    adObj: NativeAdObj,
    status: Status = 'available',
) {
    useEffect(() => {
        displayAdsLog(`useLoadNativeAd(${adObj}, ${status})`);

        if (adObj && window.apntag && window.apntag.anq) {
            window.apntag.anq.push(() => {
                if (window.apntag) {
                    window.apntag.attachClickTrackers(adObj, adObj.targetId);
                    if (status === 'loaded') {
                        displayAdsLog(`status === 'loaded'`);

                        window.apntag.fireImpressionTrackers(adObj);
                    } else {
                        displayAdsLog(
                            `status !== 'loaded' so we setup adLoaded event`,
                        );

                        window.apntag.onEvent(
                            'adLoaded',
                            adObj.targetId,
                            (adObj) => {
                                displayAdsLog(`adLoaded`, adObj);

                                if (
                                    adObj &&
                                    adObj.adType === 'native' &&
                                    adObj.source === 'rtb'
                                ) {
                                    if (window.apntag) {
                                        window.apntag.fireImpressionTrackers(
                                            adObj,
                                        );
                                    }
                                }
                            },
                        );
                    }
                }

                const element = document.getElementById(adObj.targetId);
                if (element) {
                    const cleanup = warnIfRemoved(element);
                    inscreen(element, () => {
                        displayAdsLog(`native ad ${adObj.targetId} in screen`);
                        publish('recirc-native-ad', 'in-view', adObj);
                        cleanup();
                        loadElement(adObj.targetId);
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // We only want this to run once

    return {};
}

export function useLoadNativeAdWithRef(
    adObj: NativeAdObj,
    status: Status = 'available',
    forwardedRefEl: MutableRefObject<HTMLDivElement>,
) {
    const [hasRun, setHasRun] = useState(false);
    useEffect(() => {
        displayAdsLog(`useLoadNativeAd(${adObj}, ${status})`);

        if (
            !hasRun &&
            forwardedRefEl?.current &&
            adObj &&
            window.apntag &&
            window.apntag.anq
        ) {
            setHasRun(true);
            window.apntag.anq.push(() => {
                if (window.apntag) {
                    window.apntag.attachClickTrackers(adObj, adObj.targetId);
                    if (status === 'loaded') {
                        displayAdsLog(`status === 'loaded'`);
                        window.apntag.fireImpressionTrackers(adObj);
                    } else {
                        window.apntag.onEvent(
                            'adLoaded',
                            adObj.targetId,
                            (adObj) => {
                                displayAdsLog(`adLoaded`, adObj);

                                if (isNative(adObj)) {
                                    if (window.apntag) {
                                        window.apntag.fireImpressionTrackers(
                                            adObj,
                                        );
                                    }
                                }
                            },
                        );
                    }
                }

                const element = forwardedRefEl.current;
                if (element) {
                    const cleanup = warnIfRemoved(element);
                    inscreen(element, () => {
                        displayAdsLog(`native ad ${adObj.targetId} in screen`);
                        publish('recirc-native-ad', 'in-view', adObj);
                        cleanup();
                        loadElement(adObj.targetId);
                    });
                }
            });
        }
    }, [forwardedRefEl, hasRun, adObj, status]);

    return {};
}
