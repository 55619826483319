import { displayAdsLog } from '../../utils/debug';
import { html } from 'lit';

export const WEB_COMPONENT_CSS = [
    'https://assets.finn.no/pkg/@warp-ds/css/v1/resets.css',
    'https://assets.finn.no/pkg/@warp-ds/fonts/v1/finn-no.css',
    'https://assets.finn.no/pkg/@warp-ds/css/v1/tokens/finn-no.css',
    'https://assets.finn.no/pkg/@warp-ds/css/v1/components.css',
    'ADVT_CSS_URL_INSERTED_BY_ROLLUP',
    'WARP_ADVT_CSS_URL_INSERTED_BY_ROLLUP',
];
export const buildLinkTag = (data) =>
    html`<link
        rel="${data.rel}"
        href="${data.href}"
        ${data.as ? `as="${data.as}"` : ''}
        ${data.crossorigin ? 'crossorigin' : ''}
        ${data.type ? `type="${data.type}"` : ''}
    />`;

export const warpResources = {
    links: [
        {
            href: 'https://assets.finn.no/pkg/@warp-ds/tokens/v1/finn-no.css',
            rel: 'stylesheet',
        },
        {
            href: 'https://assets.finn.no/pkg/@warp-ds/fonts/v1/finn-no.css',
            rel: 'stylesheet',
        },
        {
            href: 'https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Medium.woff2',
            rel: 'stylesheet',
            as: 'font',
            type: 'font/woff2',
            crossorigin: true,
        },
        {
            href: 'https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Light.woff2',
            rel: 'stylesheet',
            as: 'font',
            type: 'font/woff2',
            crossorigin: true,
        },
        {
            href: 'ADVT_CSS_URL_INSERTED_BY_ROLLUP',
            rel: 'stylesheet',
        },
        {
            href: 'WARP_ADVT_CSS_URL_INSERTED_BY_ROLLUP',
            rel: 'stylesheet',
        },
    ],
    fonts: [
        "@font-face { font-family: 'Finntype'; src: url('https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Light.woff2') format('woff2'), url('https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Light.woff') format('woff'); font-weight: 400; font-style: normal; }",
        "@font-face { font-family: 'Finntype'; src: url('https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Medium.woff2') format('woff2'), url('https://assets.finn.no/pkg/@warp-ds/fonts/v1/FINNTypeStrippet-Medium.woff') format('woff'); font-weight: 700; font-style: normal; }",
    ],
};
export function addSButtonSlotToRecommendation(id: string) {
    if (document) {
        const buttonId = `${id}-button`;
        const slotElem = document.createElement('div');
        slotElem.setAttribute('slot', buttonId + '_slot');
        const containerElem = document.createElement('div');
        containerElem.setAttribute('id', buttonId);
        slotElem.appendChild(containerElem);

        const recomWc = getRecomWebComponent();
        if (recomWc) {
            recomWc.appendChild(slotElem);
        }
    }
}

export function addSlotToRecommendation(id) {
    if (document) {
        const slotElem = document.createElement('div');
        slotElem.setAttribute('slot', id);
        const containerElem = document.createElement('div');
        containerElem.setAttribute('id', id);
        slotElem.appendChild(containerElem);

        const recomWc = getRecomWebComponent();
        if (recomWc) {
            recomWc.appendChild(slotElem);
        }
    }
}

export const getRecomWebComponent = () => {
    const webComponent =
        document.querySelector('data-podlet-mlt') ||
        document.querySelector('data-podlet-recommend');
    displayAdsLog(`webComponent ${Boolean(webComponent)}`);
    return webComponent;
};

export function getScriptElementData(element: HTMLElement) {
    if (typeof document !== 'undefined') {
        if (element) {
            const { textContent } = element;
            if (textContent) {
                try {
                    return JSON.parse(textContent);
                } catch (error) {
                    console.error(`Error in parsing ${element.id}`, error);
                }
            }
        }
    }

    return {};
}
