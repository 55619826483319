import { TImprovedContentMarketing } from '../../../../../../shared/types/contentMarketing';
import * as React from 'react';
import { MultiWrapper } from '../../../multi-wrapper';
import VerticalBlock from '../../../blocks/vertical-block';
import LineSvg from '../../../assets/line-svg';
import { NonCardAnchorWrapper } from '../../../components/fabric-anchor-card';

const CarResultMulti = ({ contentMarketingList, placementId }) => {
    return (
        <div className={'col-span-full py-24'}>
            <MultiWrapper
                placementId={placementId}
                contentMarketingList={contentMarketingList}
                containerClassName={'w-full'}
                flexGap={''}
            >
                {(contentMarketing: TImprovedContentMarketing, i: number) => (
                    <div key={i}>
                        <NonCardAnchorWrapper
                            contentMarketing={contentMarketing}
                        >
                            <VerticalBlock
                                hasImageRadius={true}
                                noPadding={true}
                                contentMarketing={contentMarketing}
                                isMultipleAds={true}
                            />
                        </NonCardAnchorWrapper>
                    </div>
                )}
            </MultiWrapper>
            <LineSvg />
        </div>
    );
};

export default CarResultMulti;
