import InscreenObserver from '@finn-no/inscreen-observer';

type Options = {
    proximity?: number;
    threshold?: number;
    timeout?: number;
    once?: boolean;
};

export function lazyLoad(
    element: Element | null,
    callback: (element: Element) => void,
    options?: Options,
) {
    if (typeof window === 'object') {
        const observer = new InscreenObserver(callback, {
            once: true,
            ...options,
        });

        if (element && observer) {
            observer.observe(element);
            return function cleanup() {
                observer.unobserve(element);
            };
        }
    }
    return function cleanup() {};
}

export function inscreen(
    element: Element | null,
    callback: (element: Element) => void,
    options?: Options,
) {
    return lazyLoad(element, callback, {
        threshold: 0.5,
        ...options,
    });
}

export function inview(
    element: Element | null,
    callback: (element: Element) => void,
    proximity: number = 100,
    threshold: number = 0.0,
) {
    return lazyLoad(element, callback, {
        proximity,
        threshold,
    });
}
