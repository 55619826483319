import { Feature } from '../shared/types/ast';
import { HorseshoeConfig } from '../shared/types/podlet';
import { getPodletConfigFromHtml } from './podlet/config';
import { getDeviceType } from './utils/device';
import { setConfig } from './advt-state';

let config = getPodletConfigFromHtml();

export const getConfig = (): HorseshoeConfig => {
    const deviceType = config?.deviceType || getDeviceType() || 'desktop';
    if (!config) {
        config = getPodletConfigFromHtml();
        config = {
            ...config,
            deviceType,
        };
    }
    setConfig(config);
    return {
        ...config,
        deviceType,
    };
};

// Used to get feature flags
export const getFeatureStatus = (
    toggleName: string,
    existingConfig?: HorseshoeConfig,
): boolean => {
    const config = existingConfig || getConfig();
    return (
        config?.features?.find(
            (i) => i.feature.toLowerCase() === toggleName.toLowerCase(),
        )?.enabled || false
    );
};

// Used for abtest features
export const getDetailedFeature = (toggleName: string): Feature | undefined => {
    const config = getConfig();
    return config?.features?.find(
        (i) => i.feature.toLowerCase() === toggleName.toLowerCase(),
    );
};

export const getExperimentVariantFromConfig = (
    experimentName: string,
): string | undefined => {
    const config = getConfig();

    const experiments = config?.experiments;
    if (experiments) {
        const experiment = experiments.find(
            (d) => d.feature === experimentName,
        );
        if (experiment) {
            return experiment.payload;
        } else return undefined;
    } else return undefined;
};

type ThresholdProximityReturn = {
    threshold: number;
    proximity: number;
};
export const getThresholdProximityFromExperiment = (
    experimentName: string,
): ThresholdProximityReturn => {
    const payload = getExperimentVariantFromConfig(experimentName);
    if (!payload) return undefined;

    try {
        const parsed = JSON.parse(payload);
        if (parsed) {
            return { threshold: parsed.threshold, proximity: parsed.proximity };
        }
    } catch (e) {
        console.error(e);
        return undefined;
    }
};
