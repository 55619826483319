import * as React from 'react';
import { PageType, Vertical } from '../../../../shared/types/ast';
import { TImprovedContentMarketing } from '../../../../shared/types/contentMarketing';
import CarResultMulti from './car/result/multi';
import RealEstateResultMulti from './realestate/result/multi';
import RealEstateSectionMulti from './realestate/section/multi';
import CarResultSingle from './car/result/single';
import RealEstateResultSingle from './realestate/result/single';
import RealEstateSectionSingle from './realestate/section/single';
import { useWebViewData } from '../useWebViewData';
import WebViewSingle from './webView/single';

export type Props = {
    vertical: Vertical;
    pageType: PageType;
    contentMarketing?: TImprovedContentMarketing;
    contentMarketingList?: TImprovedContentMarketing[];
    placementId?: string;
};
const VerticalSwitch = ({
    vertical,
    pageType,
    contentMarketing,
    contentMarketingList,
    placementId,
}: Props) => {
    const isMultipleAds = contentMarketingList !== undefined;
    const webViewData = useWebViewData();

    function isMatch(_vertical, _pageType, _isMultipleAds) {
        return (
            _vertical === vertical &&
            _pageType === pageType &&
            isMultipleAds === _isMultipleAds
        );
    }

    if (webViewData?.isWebView) {
        const contentMarketing =
            contentMarketingList?.length && contentMarketingList[0];

        if (!contentMarketing) return null;
        return (
            <WebViewSingle
                webViewData={webViewData}
                contentMarketing={contentMarketing}
            />
        );
    } else if (isMatch('car', 'result', false))
        return (
            <CarResultSingle
                contentMarketing={contentMarketing}
                isMultipleAds={isMultipleAds}
            />
        );
    else if (isMatch('car', 'result', true))
        return (
            <CarResultMulti
                contentMarketingList={contentMarketingList}
                placementId={placementId}
            />
        );
    else if (isMatch('realestate', 'result', false))
        return (
            <RealEstateResultSingle
                contentMarketing={contentMarketing}
                isMultipleAds={isMultipleAds}
            />
        );
    else if (isMatch('realestate', 'result', true))
        return (
            <RealEstateResultMulti
                placementId={placementId}
                contentMarketingList={contentMarketingList}
            />
        );
    else if (isMatch('realestate', 'section', false))
        return <RealEstateSectionSingle contentMarketing={contentMarketing} />;
    else if (isMatch('realestate', 'section', true))
        return (
            <RealEstateSectionMulti
                contentMarketingList={contentMarketingList}
                placementId={placementId}
            />
        );
    else return null;
};

export default VerticalSwitch;
