import * as React from 'react';
import ImageWithFocus, { Props } from './image-with-focus';

const VideoOrImage = (props: Props) => {
    const { contentMarketing } = props;

    const videoUrl = contentMarketing?.videoUrl;
    if (videoUrl) {
        return null;
    } else {
        return <ImageWithFocus {...props} />;
    }
};

export default VideoOrImage;
