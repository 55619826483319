import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';

import { render } from 'react-dom';
import { ContentMarketing } from '../../../shared/types/contentMarketing';
import * as React from 'react';
import { escapeContentMarketing } from './content-marketing-container';
import {
    buildLinkTag,
    getScriptElementData,
    warpResources,
} from '../web-components/utils';
import VerticalSwitch, { Props } from './verticals';
import { PageType, Vertical } from '../../../shared/types/ast';
import { setupCustomElements } from '../web-components';

const ADVT_CONTENT_MARKETING_SWITCH_NAME = 'advt-content-marketing-switch';

class ContentMarketingSwitchWebComponent extends LitElement {
    @property({ type: Object })
    contentMarketing: ContentMarketing = null;

    @property({ type: Array })
    contentMarketingList: ContentMarketing[] = null;

    @property({ type: String })
    pageType: PageType = null;

    @property({ type: String })
    vertical: Vertical = null;

    updated(_changedProperties) {
        super.updated(_changedProperties);

        const props = {
            pageType: this.pageType,
            vertical: this.vertical,
            contentMarketing: undefined,
            contentMarketingList: undefined,
        };
        if (this.contentMarketing) {
            props.contentMarketing = escapeContentMarketing(
                this.contentMarketing,
            );
            this.renderReact(props);
        } else if (
            Array.isArray(this.contentMarketingList) &&
            this.contentMarketingList.length > 0
        ) {
            props.contentMarketingList = this.contentMarketingList.map((ed) =>
                escapeContentMarketing(ed),
            );
            this.renderReact(props);
        } else if (typeof document !== 'undefined') {
            const contentMarketingData = getScriptElementData(
                document.getElementById('content-marketing-data'),
            );

            try {
                if (Array.isArray(contentMarketingData)) {
                    props.contentMarketingList = contentMarketingData.map(
                        (ed) => escapeContentMarketing(ed),
                    );
                } else {
                    props.contentMarketing =
                        escapeContentMarketing(contentMarketingData);
                }
                this.renderReact(props);
            } catch (e) {
                console.error(
                    `Failed to parse contentMarketingData: ${e.message}`,
                );
            }
        }
    }

    renderReact(props: Props) {
        render(
            <VerticalSwitch {...props} />,
            this.shadowRoot.getElementById(
                `${ADVT_CONTENT_MARKETING_SWITCH_NAME}-root`,
            ),
        );
    }

    render() {
        return html`
            <style>
                html {
                    font-size: 62.5%;
                }
            </style>
            ${warpResources.links.map(buildLinkTag)}
            <style>
                body {
                    margin: 0;
                }
                ${warpResources.fonts.map((fontDef) => html`${fontDef}`)}
            </style>

            <div
                id="${ADVT_CONTENT_MARKETING_SWITCH_NAME}-root"
                class="advt-placement"
            ></div>
        `;
    }
}

setupCustomElements(
    ADVT_CONTENT_MARKETING_SWITCH_NAME,
    ContentMarketingSwitchWebComponent,
);
