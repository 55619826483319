import SponsorHeader from '../../../components/sponsor-header';
import { TImprovedContentMarketing } from '../../../../../../shared/types/contentMarketing';
import * as React from 'react';
import VerticalBlock from '../../../blocks/vertical-block';
import FabricAnchorCard from '../../../components/fabric-anchor-card';

type TCarResult = {
    contentMarketing: TImprovedContentMarketing;
    isMultipleAds: boolean;
};
const CarResultSingle = ({ contentMarketing }: TCarResult) => {
    return (
        <div className={'py-8 m-2'}>
            <SponsorHeader
                contentMarketing={contentMarketing}
                className={`px-12 pb-2 pt-12 md:pl-12`}
            />

            <FabricAnchorCard contentMarketing={contentMarketing}>
                <VerticalBlock
                    contentMarketing={contentMarketing}
                    isMultipleAds={false}
                />
            </FabricAnchorCard>
        </div>
    );
};

export default CarResultSingle;
