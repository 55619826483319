import * as React from 'react';
import { Native } from '../../../shared/types/ast';
import { escape, isNonEmptyString } from '../../utils/component/helpers';

type Props = {
    native: Native;
    className?: string;
    style?: React.CSSProperties;
};

export function getDescription(native: Native) {
    let description;

    if (isNonEmptyString(native.body)) {
        // v3
        description = native.body;
    } else if (isNonEmptyString(native.fullText)) {
        // v2
        description = native.fullText;
    } else if (isNonEmptyString(native.description)) {
        // v2
        description = native.description;
    } else {
        description = '';
    }

    return escape(description);
}

const Description = ({ native, className, style }: Props) => (
    <div
        className={className}
        style={style}
        dangerouslySetInnerHTML={{ __html: getDescription(native) }}
    />
);

export default Description;
