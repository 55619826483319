import { LitElement, html } from 'lit';
import { property } from 'lit/decorators.js';

import { render } from 'react-dom';
import * as React from 'react';
import { WEB_COMPONENT_CSS } from '../web-components/utils';
import { DeviceType, NativeAdObj, Status } from '../../../shared/types/ast';
import { displayAdsLog } from '../../utils/debug';
import { Native } from './native';
import { setupCustomElements } from '../web-components';

const NATIVE_WEB_COMPONENT_NAME = 'native-web-component';

class NativeWebComponent extends LitElement {
    @property({ type: String })
    adObj: string = null;

    @property({ type: String })
    status: Status = null;

    @property({ type: String })
    id: string = null;

    @property({ type: Boolean })
    grid: boolean = null;

    @property({ type: String })
    deviceType: DeviceType = null;

    @property({ type: Boolean })
    isRecirc: boolean = false;

    @property({ type: String, state: true })
    currentId: string = this.id;

    rootName: string = `${NATIVE_WEB_COMPONENT_NAME}-root`;

    constructor() {
        console.log('NativeWebComponent constructor()');
        super();
        displayAdsLog(
            NATIVE_WEB_COMPONENT_NAME,
            this.currentId,
            'constructor()',
        );
    }

    firstUpdated(_changedProperties) {
        displayAdsLog(
            NATIVE_WEB_COMPONENT_NAME,
            this.currentId,
            'firstUpdated()',
        );

        super.firstUpdated(_changedProperties);
        this.currentId = this.id;
    }

    updated(_changedProperties) {
        displayAdsLog(NATIVE_WEB_COMPONENT_NAME, this.currentId, 'updated()');
        super.updated(_changedProperties);
        this.renderReact();
    }

    renderReact() {
        displayAdsLog(
            NATIVE_WEB_COMPONENT_NAME,
            this.currentId,
            'renderReact()',
        );
        let adObj = {};
        try {
            adObj = JSON.parse(this.adObj);
        } catch (e) {
            displayAdsLog(NATIVE_WEB_COMPONENT_NAME, this.currentId, e);
        }
        const props = {
            adObj: adObj as NativeAdObj,
            status: this.status,
            id: this.id,
            grid: this.grid,
            deviceType: this.deviceType,
            isRecirc: this.isRecirc,
            slotAd: true,
        };
        displayAdsLog(NATIVE_WEB_COMPONENT_NAME, this.currentId, { props });

        render(
            <Native {...props} />,
            this.shadowRoot.getElementById(this.rootName),
        );
    }

    render() {
        displayAdsLog(NATIVE_WEB_COMPONENT_NAME, this.currentId, 'render()');

        return html`
            ${WEB_COMPONENT_CSS.map(
                (url) => html`<link href="${url}" rel="stylesheet" />`,
            )}
            <div id="${this.rootName}" class="advt-placement"></div>
        `;
    }
}

setupCustomElements(NATIVE_WEB_COMPONENT_NAME, NativeWebComponent);
