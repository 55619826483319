import * as React from 'react';
import { NativeAdObj } from '../../../shared/types/ast';

type Props = {
    adObj: NativeAdObj;
    className?: string;
    style?: React.CSSProperties;
    dangerouslySetInnerHTML?: { __html: string };
    children: React.ReactNode;
    preventDefaultPredicate?: (
        element: EventTarget | null | undefined,
    ) => void | boolean;
};

function getClassName(className?: string) {
    return `${className ? `${className} ` : ''}`;
}

export default class VideoLink extends React.Component<Props> {
    element: HTMLElement | null | undefined;

    componentDidMount() {
        const handlePropagation = (event: MouseEvent | TouchEvent) => {
            const predicate = this.props.preventDefaultPredicate;
            if (predicate && predicate(event.target)) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            return true;
        };

        if (this.element) {
            this.element.addEventListener('click', (event: MouseEvent) =>
                handlePropagation(event),
            );
            this.element.addEventListener('mousedown', (event: MouseEvent) =>
                handlePropagation(event),
            );
            this.element.addEventListener('touchstart', (event: TouchEvent) =>
                handlePropagation(event),
            );
        }
    }

    render() {
        const {
            adObj: { native },
            className,
            style,
            dangerouslySetInnerHTML,
            children,
        } = this.props;
        // TODO: Check if we can use noreferrer on the links
        /* eslint-disable react/jsx-no-target-blank */
        return (
            <a
                ref={(element) => (this.element = element)}
                href={native.clickUrl}
                className={getClassName(className)}
                style={style}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML}
                target="_blank"
                rel="noopener"
            >
                {children}
            </a>
        );
        /* eslint-enable react/jsx-no-target-blank */
    }
}
