import * as React from 'react';
import { TImprovedContentMarketing } from '../../../../shared/types/contentMarketing';
import { getConfig } from '../../../config';
import { Clickable } from '@warp-ds/react';
import { useMemo } from 'react';

type ContentMarketingTextContainerType = {
    smallText?: boolean;
    disableTextBalancer?: boolean;
    hideSponsor?: boolean;
    contentMarketing: TImprovedContentMarketing;
};

const TextContainer = ({
    contentMarketing,
    hideSponsor = false,
}: // smallText = false,
ContentMarketingTextContainerType) => {
    const { deviceType } = getConfig();
    const isMobile = deviceType === 'mobile';
    const title = contentMarketing.title.replace(/\\n/g, '<br/>');
    const cleanTitle = contentMarketing?.title.replace(/\\n/g, '');

    const tagLine = useMemo(() => {
        return isMobile
            ? contentMarketing?.text
            : contentMarketing?.textDesktop
            ? contentMarketing?.textDesktop
            : contentMarketing?.text;
    }, [isMobile, contentMarketing.text, contentMarketing.textDesktop]);

    const accessibilityId = `cm-title-${contentMarketing.id}`;

    const renderSponsorData = (sponsorData) => (
        <div className={`s-text-subtle text-14 mb-4`}>
            <span>{sponsorData.name}</span>
        </div>
    );

    const renderTagLine = (tagLine) => (
        <div className={`mb-0 text-left text-14 sm:text-16 font-bold`}>
            {tagLine}
        </div>
    );

    const renderClickable = (href, cleanTitle, accessibilityId, title) => (
        <Clickable
            href={href}
            rel="noreferrer"
            target="_blank"
            title={cleanTitle}
            id={accessibilityId}
            className={
                'link link--dark sf-ad-link s-text hover:no-underline focus:no-underline focus-ring'
            }
        >
            <div className={`w-full text-left`} data-testid={'cm-title'}>
                {title}
            </div>
        </Clickable>
    );

    return (
        <div>
            {!hideSponsor &&
                Boolean(contentMarketing?.sponsorData?.name) &&
                renderSponsorData(contentMarketing.sponsorData)}
            {!!tagLine && renderTagLine(tagLine)}
            <h2
                className={`cm--text-container text-l sm:text-xl font-normal text-left mb-0 whitespace-pre-wrap text-ellipsis no-underline`}
                id={accessibilityId}
            >
                {renderClickable(
                    contentMarketing?.href,
                    cleanTitle,
                    accessibilityId,
                    title,
                )}
                {contentMarketing?.introduction && (
                    <div>
                        <div className={'relative'}>
                            <p
                                className={`text-14 s-text content-marketing-desc-fadeout`}
                            >
                                {contentMarketing.introduction}
                            </p>
                        </div>
                        {contentMarketing?.ctaText && (
                            <div
                                className={
                                    'mt-10 inline-block px-16 py-8 rounded-8 border border-solid s-border text-16'
                                }
                            >
                                {contentMarketing.ctaText}
                            </div>
                        )}
                    </div>
                )}
            </h2>
        </div>
    );
};

export default TextContainer;
