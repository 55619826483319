import { Props } from '../../native';
import Comment from '../../../sub/comment';
import * as React from 'react';
import { NativeTransparencyButton } from '../../parts/native-transparency-button';
import Description from '../../../sub/description';
import { NativeLogo } from '../../parts/native-logo';
import { NativeTitleWithAnchor } from '../../parts/native-title-with-anchor';
import { getImageSizes, NativeImage } from '../../parts/native-image';
import { NativeTopLeftLabel } from '../../parts/native-top-left-label';

export function GeneralResultAd(props: Props) {
    const { id, adObj, grid, accessibilityId, vertical } = props;
    const { native } = adObj;
    const list = !grid;

    return (
        <div
            id={id}
            aria-owns={accessibilityId}
            className={`relative overflow-hidden rounded-8`}
        >
            <Comment
                placement={`GeneralResultAd - ${vertical}`}
                adObj={adObj}
            />
            <div className={`flex ${list ? 'flex-row' : 'flex-col'} gap-8`}>
                <div
                    className={`relative`}
                    style={{ width: list ? '30%' : '100%' }}
                >
                    <NativeImage
                        aspectClass={`aspect-3/2`}
                        radiusClass={'rounded-8'}
                        imageSizes={getImageSizes(
                            list ? 'list' : 'grid',
                            'car',
                        )}
                        native={native}
                    />
                    <NativeTopLeftLabel
                        radiusClass={'rounded-br-8 rounded-tl-8'}
                    />
                </div>
                <div className={`font-normal ${list ? 'w-2/3' : 'w-full'}`}>
                    <div
                        className={`flex flex-row justify-between text-12 items-center`}
                    >
                        <div
                            className={`text-14 s-text-subtle flex ${
                                list ? 'flex-row mb-4' : 'flex-col'
                            } gap-8`}
                        >
                            {native.sponsoredBy}
                        </div>
                    </div>
                    <div className={'pb-64 flex flex-row items-end gap-4'}>
                        <div className={`flex flex-col`}>
                            <NativeTitleWithAnchor
                                className={'font-bold'}
                                native={native}
                                accessibilityId={accessibilityId}
                            />
                            <Description native={native} className="text-12" />
                        </div>
                        <div className={'absolute bottom-8 right-8'}>
                            <NativeLogo native={native} />
                        </div>
                    </div>
                </div>
                <NativeTransparencyButton
                    adObj={adObj}
                    className={!list ? '' : 'top-8 right-8'}
                />
            </div>
        </div>
    );
}
