export function getUnleashFeatureWithPodletPrefix(nameId: string): string {
    return 'displayads-podlet-horseshoe.' + nameId;
}

export const UNLEASH_FEATURE_NAMES = {
    // Server features
    killSwitch: 'killswitch.banners',
    appNexus9826: 'appNexus9826',
    astFallback: 'ast.fallback',
    cogWheelFallback: 'cogWheel.fallback',
    enableTransparencyCogwheel: 'enableTransparencyCogwheel',
    EAad: 'EAad',
    googleAFSEnabled: 'googleAFSEnabled',
    enableAdServerErrorLogging: 'enableAdServerErrorLogging',
    enableEmptyResponseIfNoAds: 'enableEmptyResponseIfNoAds',
    disableJobAdvertising: 'disableJobAdvertising',

    // Client features
    enableAdIntervalVariant: 'enableAdIntervalVariant',
    enableRelevantDigitalAd: 'enableRelevantDigitalAd',
    enableFixedHorseshoe: 'enableFixedHorseshoe',
    enableDisplayAdsDebugger: 'enableDisplayAdsDebugger',
    enableWebComponentAdSwitch: 'enableWebComponentAdSwitch',
    enableResultCaching: 'enableResultCaching',
    enableContentfulFetcher: 'enableContentfulFetcher',
    switchXandrContentMarketingTitles: 'switchXandrContentMarketingTitles',

    // Client experiments
    objectPageInScreenExperiment: 'objectPageInScreenExperiment',
    enableTopBannerThreshProx: 'enableTopBannerThreshProx',
    // fontExperimentContentMarketing: 'fontExperiment8B',
    proximityExperiment320400: 'proximityExperiment320400',
};
