import * as React from 'react';
import { Native, Vertical } from '../../../../shared/types/ast';

const GRID_IMAGE_SIZES =
    '(min-width: 990px) 310px, (min-width: 768px) 33vw, (min-width: 480px) 50vw';

const BAP_GRID_IMAGE_SIZES =
    '(min-width: 990px) 200px, (min-width: 768px) 33vw, 50vw';

const LIST_IMAGE_SIZES = '(min-width: 768px) 240px, 40vw';

const CARD_LIST_IMAGE_SIZES =
    '(min-width: 990px) 626px, (min-width: 768px) 66vw, (min-width: 480px) 100vw';

const REAL_ESTATE_IMAGE_SIZES =
    '(min-width: 1300px) 210px, (min-width: 1025px) 215px, (min-width: 768px) 22vw, 33vw';

export function getImageSizes(
    layout: 'list' | 'grid',
    vertical: Vertical,
    format: 'card' | 'default' = 'default',
) {
    let imgSizes = LIST_IMAGE_SIZES;
    if (format === 'card' && layout === 'list') {
        imgSizes = CARD_LIST_IMAGE_SIZES;
    } else if (vertical === 'bap' && layout === 'grid') {
        imgSizes = BAP_GRID_IMAGE_SIZES;
    } else if (vertical === 'bap') {
        imgSizes = GRID_IMAGE_SIZES;
    } else if (vertical === 'realestate') {
        imgSizes = REAL_ESTATE_IMAGE_SIZES;
    }
    return imgSizes;
}
export function getImage(native: Native): {
    src: string;
    height: number;
    width: number;
} {
    const { url, height, width } = native.image ?? {};

    // Use image url instead of path to get correct image CDN url
    // for dev and prod. The url is provided by search-quest, and
    // contains an environment specific CDN hostname.
    return {
        src: url,
        height,
        width,
    };
}
export const NativeImage = ({
    native,
    aspectClass = 'h-full w-full aspect-3/2 sm:absolute sm:inset-0',
    imageClass = '',
    imageSizes = LIST_IMAGE_SIZES,
    radiusClass = 'rounded-8 sm:rounded-0',
}: {
    native: Native;
    aspectClass?: string;
    imageClass?: string;
    imageSizes?: string;
    radiusClass?: string;
}) => {
    return (
        <div className={aspectClass}>
            <img
                loading={'lazy'}
                alt={'Annonsebilde'}
                sizes={imageSizes}
                className={`w-full h-full object-center object-cover ${imageClass} ${radiusClass}`}
                {...getImage(native)}
            />
        </div>
    );
};
