import * as React from 'react';
import { useLoadNativeAd } from '../../hooks/native';
import {
    NativeAdObj,
    Status,
    DeviceType,
    Vertical,
    Subvertical,
    PageType,
    Features,
} from '../../../shared/types/ast';
import { getConfig } from '../../config';
import NativeAdVideo from './native-components/native-ad-video';
import NativeAdContent from './native-components/native-ad-content';
import { ResultTravelAd } from './native-components/result/travel';
import { ObjectAd } from './native-components/object';
import { ResultRealestateAd } from './native-components/result/realestate';
import { ResultCarAd } from './native-components/result/car';
import { ResultBapAd } from './native-components/result/bap';
import { GeneralResultAd } from './native-components/result/general';
import { RecircAd } from './native-components/object/recirc';
import { displayAdsLog } from '../../utils/debug';
import { FeriehusResultAd } from './native-components/result/feriehus';
import { isNativeVideoOrContent } from '../../utils/ast';

export type Props = {
    adObj: NativeAdObj;
    status: Status;
    id: string;
    accessibilityId?: string;
    grid: boolean;
    tempRecircHack?: boolean;
    vertical?: Vertical;
    subvertical?: Subvertical;
    pageType?: PageType;
    deviceType?: DeviceType;
    features?: Features;
    isRecirc?: boolean;
    slotAd?: boolean;
};

type NativeTypes =
    | 'video'
    | 'content'
    | 'content-troika'
    | 'mfinn'
    | 'netboard'
    | 'recirc'
    | 'result-car'
    | 'result-realestate'
    | 'result-job'
    | 'result-mc'
    | 'result-boat'
    | 'result-prepackage'
    | 'result-travel'
    | 'result-holiday_rental'
    | 'result-bap'
    | 'result-generic'
    | 'object-generic'
    | '';
export function getNativeType(props: Props): NativeTypes {
    displayAdsLog('getNativeType', { props });
    const { adObj, vertical, subvertical, pageType, isRecirc } = props;

    if (isRecirc || adObj?.targetId?.includes('recirc')) return 'recirc';
    const nativeVideoOrContent = isNativeVideoOrContent(adObj);
    if (
        nativeVideoOrContent &&
        (nativeVideoOrContent === 'video' || nativeVideoOrContent === 'content')
    ) {
        return nativeVideoOrContent;
    }

    switch (pageType) {
        case 'result':
            if (vertical === 'car') return 'result-car';
            if (vertical === 'job') return 'result-job';
            if (vertical === 'boat') return 'result-boat';
            if (vertical === 'mc') return 'result-mc';
            else if (vertical === 'realestate') return 'result-realestate';
            else if (subvertical === 'holiday_rental')
                return 'result-holiday_rental';
            else if (subvertical === 'prepackage') return 'result-prepackage';
            else if (vertical === 'travel') return 'result-travel';
            else if (vertical === 'bap') return 'result-bap';
            else return 'result-generic';
        case 'object':
            if (vertical === 'car') return 'mfinn';
            return 'object-generic';
        default:
            return '';
    }
}

export function Native(props: Props) {
    const {
        vertical: _vertical,
        subvertical: _subvertical,
        pageType: _pageType,
        deviceType: _deviceType,
    } = getConfig();

    const {
        adObj,
        status,
        vertical = _vertical,
        subvertical = _subvertical,
        pageType = _pageType,
        deviceType = _deviceType,
    } = props;

    useLoadNativeAd(adObj, status);
    const accessibilityId = `ad-title-${adObj.creativeId}`;

    return (
        <NativeSwitch
            {...props}
            accessibilityId={accessibilityId}
            vertical={vertical?.toLowerCase() as Vertical}
            subvertical={subvertical?.toLowerCase() as Subvertical}
            pageType={pageType?.toLowerCase() as PageType}
            deviceType={deviceType?.toLowerCase() as DeviceType}
        />
    );
}

export function NativeSwitch(props: Props) {
    const { id, adObj, deviceType, vertical } = props;
    const type = getNativeType(props);
    displayAdsLog({ type });

    function idFromPodlet() {
        return `displayad-${id}`;
    }

    switch (type) {
        case 'recirc':
            return <RecircAd {...props} />;
        case 'content':
            return <NativeAdContent {...props} />;
        case 'video':
            return (
                <NativeAdVideo
                    adObj={adObj}
                    mobile={deviceType === 'mobile'}
                    vertical={vertical}
                />
            );
        case 'result-travel':
        case 'result-prepackage':
            return <ResultTravelAd {...props} />;
        case 'result-realestate':
            return <ResultRealestateAd {...props} />;
        case 'result-car':
            return <ResultCarAd {...props} />;
        case 'result-holiday_rental':
            return <FeriehusResultAd {...props} />;
        case 'result-job':
        case 'result-mc':
        case 'result-boat':
            return <GeneralResultAd {...props} />;
        case 'result-generic':
        case 'result-bap':
            return <ResultBapAd {...props} />;
        case 'mfinn':
        case 'object-generic':
            return <ObjectAd {...props} id={idFromPodlet()} />;
        default:
            return <ObjectAd {...props} />;
    }
}
