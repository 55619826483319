import * as React from 'react';

const LineSvg = () => {
    return (
        <svg
            width="100%"
            height="12px"
            viewBox="0 0 606 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="16"
                y1="7.5"
                x2="590"
                y2="7.5"
                stroke="url(#paint0_diamond_1397_116)"
            />
            <defs>
                <radialGradient
                    id="paint0_diamond_1397_116"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(303 8) rotate(180) scale(287 164738)"
                >
                    <stop stopColor="#C3CCD9" />
                    <stop
                        offset="0.996624"
                        stopColor="#C3CCD9"
                        stopOpacity="0.2"
                    />
                </radialGradient>
            </defs>
        </svg>
    );
};
export default LineSvg;
