import debounce from 'lodash.debounce';
import {
    AdError,
    AdNoBidObj,
    AdObj,
    Apntag,
    DefineTagOptions,
    PageOpts,
} from '../../shared/types/ast';
import {
    markAdAvailable,
    markSetPageOpts,
    markTagLoaded,
} from '../utils/performance';
import { loadTagsWithPrebid } from '../utils/relevant-digital-load-ads';
import { displayAdsLog } from '../utils/debug';
import diagnostics from '../utils/diagnostics';
import { getConsentOrSubscribe } from './cmp';
import { getPageOptsFromState, setKeywordsInPageOpts } from '../advt-state';

window.apntag = window.apntag || ({} as Apntag);
window.apntag.anq = window.apntag.anq || [];

/**
 * This function adds a given function to the apntag command queue.
 * This is the primary way commands should be called.
 * Before apntag.js loads, this function works as Array.push,
 * adding commands to the queue. After apntag.js loads,
 * this function will execute commands immediately.
 * @param callback
 */
export function pushToAnqSafe(callback: () => void) {
    if (window.apntag && window.apntag.anq) {
        window.apntag.anq.push(() => {
            if (window.apntag) {
                callback();
            }
        });
    }
}

/**
 * Set Page Opts for the eventual Xandr request done from loadTags
 * @param pageOpts
 * @param callback if more actions should be added to the same queue
 */
export function setPageOpts(pageOpts: PageOpts, callback?: () => void) {
    if (pageOpts) {
        pushToAnqSafe(() => {
            markSetPageOpts();
            displayAdsLog(
                `pageOpts.keywords ${JSON.stringify(pageOpts.keywords)} `,
            );
            window.apntag.setPageOpts(pageOpts);
            if (callback) callback();
        });
    }
}

export function refreshApntag() {
    if (window.apntag) {
        pushToAnqSafe(() => {
            displayAdsLog('refresh');
            window.apntag.refresh();
        });
    }
}

/**
 * Define a tag to be loaded by loadTags()
 * @param tag
 */
export function defineTag(tag: DefineTagOptions) {
    if (tag && window.apntag) {
        displayAdsLog(`defineTag ${tag.targetId} `);
        window.apntag.defineTag(tag);
    }
}

export function showTag(id: string) {
    if (window.apntag) {
        displayAdsLog(`showTag ${id} `);
        window.apntag.showTag(id);
    }
}

/**
 * This function makes an ad server call to load ads that have been defined by defineTag calls.
 * loadTags(['targetId1', 'targetId2'])
 * The parameters listed below can be sent as arguments in the function.
 *
 * After implementing relevant-digital prebid, we invoke that, which then invokes loadTags([])
 */
export function loadAstTags() {
    getConsentOrSubscribe((permissionValue) => {
        displayAdsLog({ permissionValue });
        setKeywordsInPageOpts({
            'aa-sch-cmp_advertising': [permissionValue],
        });

        setPageOpts(getPageOptsFromState(), () => {
            pushToAnqSafe(() => {
                displayAdsLog('initiate loadTagsWithPrebid from queue');
                loadTagsWithPrebid(window.apntag);
            });
        });
    });
}

const debouncedFunctionsMap = {}; // Initialize a map to hold debounced functions

export function debouncedLoadTags(debounceMs: number = 100): any {
    // Check if a debounced function with this debounce time already exists
    if (!debouncedFunctionsMap[debounceMs]) {
        // If not, create a new debounced function for this debounce time
        debouncedFunctionsMap[debounceMs] = debounce(loadAstTags, debounceMs);
    }
    return debouncedFunctionsMap[debounceMs];
}

export function debouncedLoadTagsSafe(debounceMs: number = 100): any {
    displayAdsLog('debouncedLoadTagsSafe');
    // Always use the local debounced function
    const debouncedFunc = debouncedLoadTags(debounceMs);
    debouncedFunc();
}
/**
 * EVENTS
 */

export function onAdRequested(id: string, callback: (arg: AdNoBidObj) => void) {
    displayAdsLog(`SETUP onAdRequested for id: ${id}`);
    if (window.apntag)
        window.apntag.onEvent('adRequested', id, (adObj: AdNoBidObj) => {
            if (window.apntag) {
                displayAdsLog(`EVENT: onAdRequested for id: ${id}`);
                callback(adObj);
            }
        });
}
export function onAdAvailable(id: string, callback: (arg: AdObj) => void) {
    displayAdsLog(`SETUP onAdAvailable for id: ${id}`);
    if (window.apntag)
        window.apntag.onEvent('adAvailable', id, (adObj: AdObj) => {
            if (window.apntag) {
                displayAdsLog(`EVENT: onAdAvailable for id: ${id}`);
                markAdAvailable(id);
                callback(adObj);
            }
        });
}
export function onAdLoaded(id: string, callback: (arg: AdObj) => void) {
    displayAdsLog(`SETUP onAdLoaded for id: ${id}`);
    if (window.apntag) {
        window.apntag.onEvent('adLoaded', id, (adObj: AdObj) => {
            if (window.apntag) {
                displayAdsLog(`EVENT: onAdLoaded for id: ${id}`);
                markTagLoaded(id);
                callback(adObj);
            }
        });
    }
}

export function onAdNoBid(id: string, callback: (arg: AdNoBidObj) => void) {
    displayAdsLog(`SETUP onAdNoBid for id: ${id}`);
    if (window.apntag) {
        window.apntag.onEvent('adNoBid', id, (adObj) => {
            diagnostics.log(`adNoBid for id: ${id}`);
            displayAdsLog(`EVENT: adNoBid for id: ${id}`);
            if (window.apntag) {
                callback(adObj);
            }
        });
    }
}

export function onAdBadRequest(id: string, callback: (arg: AdError) => void) {
    displayAdsLog(`SETUP onAdBadRequest for id: ${id}`);
    if (window.apntag) {
        window.apntag.onEvent('adBadRequest', id, (adError: AdError) => {
            diagnostics.log(`adBadRequest on ${id} ${adError?.errMessage}`);
            displayAdsLog(`EVENT: onAdBadRequest for id: ${id}`);
            if (window.apntag) {
                callback(adError);
            }
        });
    }
}
