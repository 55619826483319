import Logo from '../../sub/logo';
import * as React from 'react';

export const NativeLogo = ({ native }) => {
    return (
        <div style={{ width: 48, minWidth: 48 }}>
            <Logo native={native} className={'h-full w-full object-cover'} />
        </div>
    );
};
