import type { AdObj, NativeAdObj, CsmAdObj } from '../../shared/types/ast';
import { RtbAdObj } from '../../shared/types/ast';

export function isMediation(adObj: AdObj): adObj is CsmAdObj {
    return adObj.source === 'csm';
}

export function isNative(adObj: AdObj): adObj is NativeAdObj {
    return adObj && adObj.adType === 'native' && adObj.source === 'rtb';
}

const allowedTakeoverTags = ['board_4'];

export function isTakeover(tagName: string, adObj: RtbAdObj) {
    const isAllowedTag = allowedTakeoverTags.includes(tagName);
    return (
        isAllowedTag &&
        adObj.height &&
        adObj.width &&
        adObj.height === 3 &&
        adObj.width === 3
    );
}

export function isNativeVideoOrContent(adObj): 'video' | 'content' | '' {
    if (adObj?.native?.video && adObj?.native?.video?.content) {
        return 'video';
    }
    if (adObj?.native?.desc2) {
        return adObj.native.desc2;
    }
    if (adObj?.native?.custom) {
        return adObj.native.custom.type;
    }
    return '';
}
