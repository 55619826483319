import * as React from 'react';
import { getConfig } from '../config';
import { useShowTag } from '../hooks/ad';
import { getBannerProximityByPlacementId } from '../utils/banner-proximity';
import { PlacementId } from '../../shared/types/ast';
import { useEffect, useMemo } from 'react';
import { displayAdsLog } from '../utils/debug';

export type Props = {
    id: string;
    targetId: string;
    pageType?: string;
    className?: string;
    bannerClassName?: string;
    deviceType?: string;
    vertical?: string;
    grid?: boolean;
    adSize?: {
        width: number;
        height: number;
    };
    slotAd?: boolean;
};

export const SlotOrDiv = ({
    slotAd,
    children,
    id,
    className,
    ...rest
}: {
    slotAd: boolean;
    children?: React.ReactNode;
    id: string;
    className?: string;
    [_: string]: any;
}) =>
    slotAd ? (
        <slot name={`${id}_slot`} className={className} id={id} {...rest}>
            {children}
        </slot>
    ) : (
        <div className={className} id={id} {...rest}>
            {children}
        </div>
    );

export function Banner(props: Props) {
    const {
        id,
        targetId,
        pageType,
        className = 'grid overflow-hidden relative transition-all outline-none col-span-full',
        bannerClassName = '',
        adSize,
        slotAd,
    } = props;

    displayAdsLog(`Render Banner ${id} targetId: ${targetId}`);

    const { subvertical } = getConfig();
    const noFabricOnPage = subvertical === 'prepackage';

    const { proximity = 0, threshold = 0.0 } = useMemo(() => {
        return getBannerProximityByPlacementId(id as PlacementId, adSize);
    }, [id, adSize]);

    useShowTag(targetId, proximity, threshold);

    // Hack to fix Relevant Digital & Xandr size bug
    useEffect(() => {
        const id = `div_utif_${targetId}`;
        const utifDiv = document.getElementById(id);
        if (utifDiv) {
            // remove all utifDiv styles
            utifDiv.style.height = '';
        }
    });

    if (pageType && pageType === 'object') {
        // Rendered from objectpages-podium already
        return null;
    }
    let containerClassName = className;
    let boardClassName = bannerClassName || 'inline mx-auto';

    if (noFabricOnPage) {
        containerClassName =
            'ads__unit ads__unit--banner ads__unit--fullwidth banners';
        boardClassName = `inline-container ${id}-inline-container`;
    }

    const style = adSize?.width ? { width: adSize.width } : {};
    return (
        <div id={`${targetId}-container`} className={containerClassName}>
            <SlotOrDiv
                style={style}
                id={targetId}
                className={boardClassName}
                slotAd={slotAd}
            >
                <span className="ad-info-wrap">
                    <div
                        id={`${targetId}-button`}
                        className="ad-info"
                        style={{ maxWidth: 26, maxHeight: 26, zIndex: 1 }}
                    />
                </span>
            </SlotOrDiv>
        </div>
    );
}
