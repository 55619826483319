import { useMemo } from 'react';
import { getScriptElementData } from '../web-components/utils';
import { ContentMarketingWebViewData } from '../../../shared/types/contentMarketing';

export function useWebViewData(): ContentMarketingWebViewData {
    return useMemo(() => {
        if (typeof document === 'undefined') {
            return {};
        }
        return (
            getScriptElementData(
                document.getElementById('content-marketing-web-view-data'),
            ) || {}
        );
    }, []);
}
