import * as React from 'react';
import Comment from '../../sub/comment';
import VideoLink from '../../sub/video-link';
import { NativeAdObj, Vertical } from '../../../../shared/types/ast';
import Logo from '../../sub/logo';
import Title from '../../sub/title';
import Video from '../../sub/video';
import TransparencyButton from '../../sub/transparencyButton';
import Description, { getDescription } from '../../sub/description';
import Badge from '../../sub/badge';

type Props = {
    adObj: NativeAdObj;
    mobile?: boolean;
    pageType?: string;
    vertical?: Vertical;
};

const NativeAdVideo = ({
    adObj,
    adObj: { native },
    mobile,
    pageType,
    vertical,
}: Props) => {
    const isDescriptionEmpty = !(getDescription(native).length > 0);
    const isObjectPage = pageType && pageType === 'object';
    const isNoneObjectPageMobile = mobile && !isObjectPage;

    const deviceDependentContentStyle = isNoneObjectPageMobile
        ? 'mt-8 bg-white'
        : 's-bg-primary-subtle';

    const TitleSection = (
        <h2
            className={`ads__unit__content__title mb-0 ${
                isDescriptionEmpty ? '' : 'pt-8'
            }`}
            id={`result-item-heading-${adObj.targetId}`}
            style={{
                display: isDescriptionEmpty ? 'flex' : 'block',
                alignItems: isDescriptionEmpty ? 'center' : '',
            }}
        >
            <Title native={native} />
        </h2>
    );

    const MediaSection = (
        <div
            style={{
                borderRadius: isNoneObjectPageMobile
                    ? '8px 8px 8px 8px'
                    : '8px 8px 0 0',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Video native={native} id={adObj.targetId} />
        </div>
    );

    const LogoAndTextSection = isDescriptionEmpty ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
            className="mt-8"
        >
            {TitleSection}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Logo
                    native={native}
                    style={{
                        maxHeight: '48px',
                        maxWidth: '75px',
                    }}
                />
            </div>
        </div>
    ) : (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {TitleSection}
                <Description
                    native={native}
                    className="ads__unit__content__list pr-8 pt-4"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    marginTop: '8px',
                }}
            >
                <Logo
                    native={native}
                    style={{
                        maxHeight: '48px',
                        maxWidth: '75px',
                    }}
                />
            </div>
        </div>
    );

    const BadgeAndCogwheelSection = (
        <div className="ads__unit__content__status relative text-12">
            <Badge native={native} style={{ color: 'rgb(118, 118, 118)' }} />
            <div className="absolute right-0" style={{ top: '-5px' }}>
                <TransparencyButton
                    adObj={adObj}
                    style={{
                        filter: '',
                        zIndex: 1,
                        opacity: 0.6,
                        backgroundColor: mobile ? '' : '#f6f8fb',
                    }}
                    placement="right"
                />
            </div>
        </div>
    );

    const ContentSection = (
        <div
            className={`ads__unit__content  ${deviceDependentContentStyle}`}
            style={{
                width: '100%',
                borderRadius: isNoneObjectPageMobile ? '0' : '0 0 8px 8px',
                padding: mobile
                    ? isObjectPage
                        ? '8px 16px 16px 16px'
                        : '0 0 8px 0'
                    : '16px',
            }}
        >
            {BadgeAndCogwheelSection}
            {LogoAndTextSection}
        </div>
    );

    return (
        <VideoLink
            adObj={adObj}
            style={{
                height: mobile ? 'auto' : '100%',
                width: '100%',
                textDecoration: 'none',
                color: '#474445',
            }}
            preventDefaultPredicate={predicate}
        >
            <div
                id={adObj.targetId}
                className={
                    vertical === 'realestate' ? `shadow-m rounded-8` : ''
                }
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <Comment placement="native-video" adObj={adObj} />
                {MediaSection}
                {ContentSection}
            </div>
        </VideoLink>
    );
};

export default NativeAdVideo;

function isVideoPlayer(element: Element): boolean {
    if (element.classList && element.classList.value)
        // @ts-ignore
        return ' ' + element.classList.value.indexOf('jw-') > -1;
    return false;
}

function isButton(element: Element) {
    return element.getAttribute('role') === 'button';
}

function isSlider(element: Element) {
    return element.classList.contains('jw-slider-volume');
}
function isVolumeController(element: Element) {
    return element.classList.contains('jw-icon-volume');
}
function isFullScreenButton(element: Element) {
    return element.classList.contains('jw-icon-fullscreen');
}

function predicate(target: EventTarget | null | undefined) {
    if (target instanceof HTMLElement) {
        return (
            isVideoPlayer(target) &&
            (isButton(target) ||
                isSlider(target) ||
                isVolumeController(target) ||
                isFullScreenButton(target))
        );
    }
    return false;
}
