import * as React from 'react';
import SponsorImage from './sponser-image';
import {
    ContentMarketingWebViewData,
    TImprovedContentMarketing,
} from '../../../../shared/types/contentMarketing';
import FinnLogoSvg from '../assets/finn-logo-svg';

type TSponsorHeader = {
    contentMarketing: TImprovedContentMarketing;
    className?: string;
    webViewData?: ContentMarketingWebViewData;
};

export const SponsorHeader = ({
    contentMarketing,
    className,
    webViewData,
}: TSponsorHeader) => {
    const { isDarkMode } = webViewData || {};

    return (
        <div
            className={`cm--sponsor-header flex w-full justify-between py-4 items-center px-8 ${
                isDarkMode ? 'rounded-t-8 ' : ''
            } ${className || ''}`}
            style={isDarkMode ? { backgroundColor: 'var(--w-white)' } : {}}
        >
            <div className={`s-text-subtle text-16`}>
                <div className={'flex items-center'}>
                    <FinnLogoSvg />
                    <span className={'ml-8'}>Annonsørinnhold</span>
                </div>
            </div>
            <SponsorImage contentMarketing={contentMarketing} />
        </div>
    );
};

export default SponsorHeader;
