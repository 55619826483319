import * as React from 'react';
import ImageWithFocus from '../components/image-with-focus';
import { TImprovedContentMarketing } from '../../../../shared/types/contentMarketing';
import TextContainer from '../components/text-container';
import SponsorImage from '../components/sponser-image';

type THorizontalContentMarketingBlock = {
    contentMarketing: TImprovedContentMarketing;
    noPadding?: boolean;
};

const HorizontalBlock = ({
    contentMarketing,
    noPadding,
}: THorizontalContentMarketingBlock) => {
    return (
        <div className={'flex'} data-testid={'cm-horizontal-block'}>
            {contentMarketing.imagePosition === 'left' ? (
                <>
                    <HorizontalImageWithSponsor
                        contentMarketing={contentMarketing}
                    />
                    <HorizontalTextContent
                        noPadding={noPadding}
                        contentMarketing={contentMarketing}
                    />
                </>
            ) : (
                <>
                    <HorizontalTextContent
                        noPadding={noPadding}
                        contentMarketing={contentMarketing}
                    />
                    <HorizontalImageWithSponsor
                        contentMarketing={contentMarketing}
                    />
                </>
            )}
        </div>
    );
};

export default HorizontalBlock;

const HorizontalImageWithSponsor = ({
    contentMarketing,
}: {
    contentMarketing: TImprovedContentMarketing;
}) => {
    return (
        <div className={`w-2/5 relative flex`}>
            <ImageWithFocus
                alt="Annonsebilde"
                contentMarketing={contentMarketing}
                isMultipleAds={false}
            />
            <div
                className={
                    'absolute text-12 top-0 left-0 px-8 py-4 rounded-br-lg s-bg-subtle'
                }
                style={{ borderBottomRightRadius: 8 }}
            >
                Annonsørinnhold
            </div>
        </div>
    );
};

type THorizontalTextContent = {
    contentMarketing: TImprovedContentMarketing;
    noPadding?: boolean;
};

const HorizontalTextContent = ({
    contentMarketing,
    noPadding,
}: THorizontalTextContent) => {
    return (
        <div className={`w-3/5 ${noPadding ? 'py-16' : 'p-16'} flex flex-col`}>
            <div className={'flex items-center justify-between mb-8'}>
                <SponsorImage contentMarketing={contentMarketing} />
                {contentMarketing.sponsorData?.name ? (
                    <span className={'text-12'}>
                        {contentMarketing.sponsorData.name}
                    </span>
                ) : (
                    ''
                )}
            </div>

            <div className={`flex flex-col h-full`}>
                <TextContainer
                    hideSponsor={true}
                    contentMarketing={contentMarketing}
                    disableTextBalancer={true}
                />
            </div>
        </div>
    );
};
