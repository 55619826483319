import * as React from 'react';

export const NativeTopLeftLabel = ({
    text = 'Annonse',
    radiusClass = 'rounded-br-8',
    ...props
}) => (
    <div
        className={`absolute top-0 left-0 ${radiusClass} text-12 px-8 py-4 z-1`}
        style={{
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
        }}
        {...props}
    >
        {text}
    </div>
);
