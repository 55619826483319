import * as React from 'react';

type Props = {
    src: string;
    initializeSvpPlayer: () => void;
};
let scriptLoaded: { [key: string]: boolean } = {};
export default class Script extends React.Component<Props> {
    componentDidMount() {
        const src = this.props.src;
        if (document && document.body) {
            if ('SVP' in window) {
                this.props.initializeSvpPlayer();
            } else if (!scriptLoaded[src]) {
                const element = document.createElement('script');
                element.setAttribute('src', src);
                document.body.appendChild(element);
                scriptLoaded[src] = true;
            }
        }
    }

    render() {
        return null;
    }
}
