import * as React from 'react';
import { MultiWrapper } from '../../../multi-wrapper';
import VerticalBlock from '../../../blocks/vertical-block';
import FabricAnchorCard from '../../../components/fabric-anchor-card';

const RealEstateResultMulti = ({ contentMarketingList, placementId }) => {
    return (
        <MultiWrapper
            placementId={placementId}
            contentMarketingList={contentMarketingList}
            flexGap={'gap-8'}
        >
            {(contentMarketing, i: number) => (
                <FabricAnchorCard
                    contentMarketing={contentMarketing}
                    key={i}
                    className={'h-full'}
                >
                    <VerticalBlock
                        isMultipleAds={true}
                        contentMarketing={contentMarketing}
                    />
                </FabricAnchorCard>
            )}
        </MultiWrapper>
    );
};

export default RealEstateResultMulti;
