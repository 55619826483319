import { Props } from '../../native';
import Comment from '../../../sub/comment';
import * as React from 'react';
import Description from '../../../sub/description';
import { NativeLogo } from '../../parts/native-logo';
import { NativeTransparencyButton } from '../../parts/native-transparency-button';
import { NativeTopLeftLabel } from '../../parts/native-top-left-label';
import { getImageSizes, NativeImage } from '../../parts/native-image';
import { NativeTitleWithAnchor } from '../../parts/native-title-with-anchor';

const c = {
    ad: 'relative overflow-hidden transition-all outline-none sf-ad-outline sf-ad-card rounded-8 relative grid f-grid grid-cols-3 grid-rows-2',
    imageWrapper:
        'sm:m-0 col-start-1 col-span-3 sm:col-start-3 sm:row-span-2 sm:relative sf-realestate-image',
    imageAspect: 'h-full aspect-3/2 sm:absolute sm:inset-0',
    image: 'w-full h-full object-center object-cover rounded-8 sm:rounded-0',
    content: 'col-span-3 sm:col-span-2 m-16 mt-8',
    sponsor: 'col-span-2 sm:row-start-1 m-16 mt-8 mb-0 pr-44 sm:p-0',
    heading: 'mb-0',
};
export function ResultRealestateAd(props: Props) {
    const { id, adObj, accessibilityId } = props;
    const { native } = adObj;

    return (
        <div
            id={id}
            aria-owns={accessibilityId}
            className={c.ad}
            style={{ gridTemplateRows: 'min-content 1fr' }}
        >
            <span className={'absolute'}>
                <Comment placement="ResultRealestateAd" adObj={adObj} />
            </span>
            <div className={c.imageWrapper}>
                <NativeImage
                    native={native}
                    imageSizes={getImageSizes('list', 'realestate')}
                />
                <NativeTopLeftLabel />
                <NativeTransparencyButton adObj={adObj} />
            </div>
            <div className={c.sponsor}>
                <div className={'flex flex-row items-center'}>
                    <NativeLogo native={native} />
                    <span className={'ml-16 text-12 s-text-subtle'}>
                        {native.sponsoredBy}
                    </span>
                </div>
            </div>
            <div className={c.content}>
                <NativeTitleWithAnchor
                    className={`${c.heading} t4 font-bold`}
                    native={native}
                    accessibilityId={accessibilityId}
                />
                <Description native={native} className="font-normal text-14" />
            </div>
        </div>
    );
}
