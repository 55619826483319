export const urlHasHttp = (url: string) => {
    return url.indexOf('http://') === 0 || url.indexOf('https://') === 0;
};

export const setUrlHttpsIfMissing = (url?: string) => {
    if (!url) return '';
    if (urlHasHttp(url)) {
        return url;
    } else return `https://${url}`;
};

export const getFirstChar = (str: string) => {
    return str.charAt(0);
};

export function replaceLastCharacterOfString(str: string, char: string) {
    return str.substring(0, str.length - 1) + char;
}

export function textWithEllipse(text: string, length: number = 100): string {
    if (!text) return '';
    return text?.length > length ? text.substring(0, length) + '...' : text;
}
