import * as React from 'react';
import TextContainer from '../components/text-container';
import {
    ContentMarketingWebViewData,
    TImprovedContentMarketing,
} from '../../../../shared/types/contentMarketing';
import VideoOrImage from '../components/video-or-image';

type TVerticalContentMarketingBlock = {
    contentMarketing: TImprovedContentMarketing;
    isMultipleAds: boolean;
    noPadding?: boolean;
    hasImageRadius?: boolean;
    webViewData?: ContentMarketingWebViewData;
};

const VerticalBlock = ({
    contentMarketing,
    isMultipleAds,
    noPadding,
    hasImageRadius,
    webViewData,
}: TVerticalContentMarketingBlock) => {
    const { isDarkMode, isWebView } = webViewData || {};
    return (
        <div data-testid={'cm-vertical-block'} className={'flex flex-col'}>
            <VideoOrImage
                hasImageRadius={hasImageRadius}
                alt="Annonsebilde"
                contentMarketing={contentMarketing}
                isMultipleAds={isMultipleAds}
            />
            <div
                className={`py-16 ${
                    noPadding ? '' : isWebView ? 'px-8' : 'px-16'
                } ${contentMarketing?.videoUrl ? 'relative' : ''}`}
                style={
                    isDarkMode
                        ? {
                              backgroundColor: 'var(--w-gray-800)',
                              color: 'var(--w-s-color-text-inverted',
                          }
                        : {
                              color: 'var(--w-s-color-text-default',
                          }
                }
            >
                <TextContainer
                    smallText={isMultipleAds}
                    disableTextBalancer={isMultipleAds}
                    contentMarketing={contentMarketing}
                />
            </div>
        </div>
    );
};

export default VerticalBlock;
