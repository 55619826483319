import { getConfig } from '../../config';
import SponsorHeader from './components/sponsor-header';
import { TImprovedContentMarketing } from '../../../shared/types/contentMarketing';
import * as React from 'react';
import { ContentMarketingElement } from './content-marketing-element';

/**
 *
 * @param children
 * @param placementId
 * @param contentMarketingList
 * @param containerClassName
 * @param flexGap
 * @constructor
 * @return a list of elements with a render props which returns contentMarketing and index
 */
export const MultiWrapper = ({
    children,
    placementId,
    contentMarketingList,
    containerClassName = 'col-span-full',
    flexGap = 'gap-8',
}) => {
    const { deviceType, vertical } = getConfig();

    const carouselStyle =
        vertical === 'realestate'
            ? {
                  width: `calc(100vw)`,
                  marginLeft: -22,
                  paddingLeft: 16,
                  paddingRight: 22,
              }
            : {
                  width: 'calc(100vw + 16px)',
                  marginLeft: -32,
                  paddingLeft: 32,
                  paddingRight: 32,
              };
    if (deviceType === 'mobile') {
        return (
            <div className={containerClassName}>
                {Array.isArray(contentMarketingList) && (
                    <SponsorHeader contentMarketing={contentMarketingList[0]} />
                )}
                <div
                    className={'cm-carousel-container flex flex-row' + flexGap}
                    style={carouselStyle}
                >
                    {contentMarketingList.map(
                        (
                            contentMarketing: TImprovedContentMarketing,
                            i: number,
                        ) => (
                            <div
                                key={i}
                                className={
                                    'cm-carousel-child flex-auto w-2/4 m-4'
                                }
                            >
                                <div className={'h-full'}>
                                    <ContentMarketingElement
                                        key={contentMarketing.id}
                                        placementId={placementId}
                                        contentMarketing={contentMarketing}
                                        multiAdIndex={i}
                                        isMultipleAds={true}
                                    >
                                        <div
                                            data-testid={'cm-carousel-block'}
                                            className={'flex flex-col h-full'}
                                            style={{ width: '70vw' }}
                                        >
                                            {children(contentMarketing, i)}
                                        </div>
                                    </ContentMarketingElement>
                                </div>
                            </div>
                        ),
                    )}
                </div>
            </div>
        );
    }
    return (
        <div className={containerClassName}>
            {Array.isArray(contentMarketingList) && (
                <SponsorHeader contentMarketing={contentMarketingList[0]} />
            )}
            <div className={'flex flex-row ' + flexGap}>
                {contentMarketingList.map(
                    (
                        contentMarketing: TImprovedContentMarketing,
                        i: number,
                    ) => (
                        <div key={i} className={'flex-auto w-2/4'}>
                            <div className={'h-full'}>
                                <ContentMarketingElement
                                    key={contentMarketing.id}
                                    placementId={placementId}
                                    contentMarketing={contentMarketing}
                                    multiAdIndex={i}
                                    isMultipleAds={true}
                                >
                                    {children(contentMarketing, i)}
                                </ContentMarketingElement>
                            </div>
                        </div>
                    ),
                )}
            </div>
        </div>
    );
};
