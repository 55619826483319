import * as React from 'react';
import { Native } from '../../../shared/types/ast';
import Script from './script';

type SVPPlayer = any;
type SVPEventData = any;
declare var SVP: { Player: SVPPlayer };

type Props = {
    id: string;
    native: Native;
};

type State = {
    interaction: boolean;
};

export default class Video extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            interaction: false,
        };
    }

    initializeSvpPlayer() {
        const { id, native } = this.props;
        if (native.video) {
            const content = native.video.content;
            const videoPoster = native.image ? native.image.url : '';

            if (content) {
                const trimmedContent = content.replace(
                    new RegExp('<ClickThrough[\\d\\D]*?/ClickThrough>', 'g'),
                    '',
                );

                const blobURL = URL.createObjectURL(
                    new Blob([trimmedContent], {
                        type: 'text/xml',
                    }),
                );
                try {
                    if ('SVP' in window) {
                        const svp = new SVP.Player({
                            id,
                            vendor: 'appnexus',
                            node: 'ad-player-' + id,
                            autoplay: false,
                            mute: true,
                            skin: {
                                name: 'vgtv',
                            },
                            poster: videoPoster,
                            settings: {
                                jw: {
                                    abouttext: 'Annonse',
                                    advertising: {
                                        client: 'vast',
                                        tag: blobURL,
                                    },
                                },
                            },
                        });

                        svp.on('adPause', (data: SVPEventData) => {
                            if (data.pauseReason === 'interaction') {
                                this.setState({ interaction: true });
                            }
                        });

                        svp.on('ready', () => {
                            const player = svp.model.player;
                            player.on('viewable', (isVisible: SVPEventData) => {
                                if (!this.state.interaction) {
                                    if (isVisible.viewable) {
                                        player.play();
                                    } else {
                                        player.pause();
                                    }
                                }
                            });
                        });
                    }
                } catch (ex) {
                    console.error(ex);
                }
            }
        }
    }

    componentDidMount() {
        window.addEventListener('onSvpPlayerReady', () => {
            this.initializeSvpPlayer();
        });
    }

    render() {
        const { id } = this.props;
        return (
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '0',
                    paddingTop: '56.25%',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <div id={'ad-player-' + id} />
                </div>
                <Script
                    src="https://cdn.stream.schibsted.media/player/3.26.1/player.min.js"
                    initializeSvpPlayer={() => this.initializeSvpPlayer()}
                />
            </div>
        );
    }
}
