import {
    CreativeClickProps,
    logCreativeLoad,
    logVideoLoad,
} from '../../utils/logger';
import { TImprovedContentMarketing } from '../../../shared/types/contentMarketing';
import { useEffect, useMemo, useState } from 'react';
import { getConfig } from '../../config';

type TUsePulseLoadAndClick = {
    placementId: string;
    contentMarketing: TImprovedContentMarketing;
    isMultipleAds: boolean;
    disablePulseTracking: boolean;
    multiAdIndex: number;
};

export const usePulseLoadAndClick = ({
    placementId: originalPlacementId,
    contentMarketing,
    isMultipleAds,
    multiAdIndex,
    disablePulseTracking = false,
}: TUsePulseLoadAndClick): CreativeClickProps => {
    const [pulseInitiated, setPulseInitiated] = useState(false);
    const config = getConfig();

    const { adType, elementType, placementId, creativeId } = useMemo(() => {
        const adType = 'Content';
        const elementType = isMultipleAds
            ? multiAdIndex === 0
                ? 'Split Left'
                : 'Split Right'
            : undefined;

        const placementId = isMultipleAds
            ? `${originalPlacementId}_split_${multiAdIndex + 1}`
            : originalPlacementId;
        let creativeId = contentMarketing.id;

        if (contentMarketing.variantName) {
            creativeId += `_${contentMarketing.variantName}`;
        }

        return { adType, elementType, placementId, creativeId };
    }, [
        isMultipleAds,
        multiAdIndex,
        originalPlacementId,
        contentMarketing.id,
        contentMarketing.variantName,
    ]);

    const creativeClickObject: CreativeClickProps = useMemo(
        () => ({
            creativeId,
            elementId: placementId,
            verticalName: config.vertical,
            subVertical: config.subvertical,
            targetId: contentMarketing.href,
            variantName: contentMarketing.variantName,
            provider: contentMarketing.sponsorData.name,
            adType,
            elementType,
            adFormat: isMultipleAds ? 'Split-view' : 'Single-view',
        }),
        [
            creativeId,
            placementId,
            config.vertical,
            config.subvertical,
            contentMarketing.href,
            contentMarketing.variantName,
            contentMarketing.sponsorData.name,
            adType,
            elementType,
            isMultipleAds,
        ],
    );

    useEffect(() => {
        if (
            !disablePulseTracking &&
            !pulseInitiated &&
            document &&
            placementId &&
            contentMarketing &&
            creativeId
        ) {
            if (contentMarketing.videoUrl) {
                logVideoLoad(
                    creativeId,
                    config.vertical,
                    config.subvertical,
                    0,
                    0,
                );
            }
            logCreativeLoad({
                ...creativeClickObject,
            });

            setPulseInitiated(true);
        }
    }, [
        disablePulseTracking,
        placementId,
        pulseInitiated,
        creativeId,
        contentMarketing,
        creativeClickObject,
        config.vertical,
        config.subvertical,
    ]);

    return disablePulseTracking ? null : creativeClickObject;
};
