import * as React from 'react';
import { AdObj } from '../../../shared/types/ast';

const version = '7.0.0';

type Props = {
    placement: string;
    adObj: AdObj;
};

const Comment = ({ placement, adObj }: Props) => (
    <span
        dangerouslySetInnerHTML={{
            __html: `<!-- ${placement} - Creative Id ${
                adObj.creativeId || 'unknown'
            }  (v.${version}) -->`,
        }}
    />
);

export default Comment;
