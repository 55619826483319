import SponsorHeader from '../../components/sponsor-header';
import * as React from 'react';
import VerticalBlock from '../../blocks/vertical-block';
import FabricAnchorCard from '../../components/fabric-anchor-card';
import { TImprovedContentMarketing } from '../../../../../shared/types/contentMarketing';
import { ContentMarketingWebViewData } from '../../../../../shared/types/contentMarketing';

const WebViewSingle = ({
    contentMarketing,
    webViewData,
    isMultipleAds = false,
}: {
    contentMarketing: TImprovedContentMarketing;
    isMultipleAds?: boolean;
    webViewData?: ContentMarketingWebViewData;
}) => {
    return (
        <div>
            <SponsorHeader
                contentMarketing={contentMarketing}
                webViewData={webViewData}
            />
            <FabricAnchorCard
                contentMarketing={contentMarketing}
                webViewData={webViewData}
            >
                <VerticalBlock
                    isMultipleAds={isMultipleAds}
                    contentMarketing={contentMarketing}
                    webViewData={webViewData}
                />
            </FabricAnchorCard>
        </div>
    );
};

export default WebViewSingle;
