import debounce from 'lodash.debounce';
import { PerformanceEntry } from '../../shared/types/ast';
import diagnostics from './diagnostics';
import { Finn, BannerGlobal } from '../../shared/types/ast';

const responseEnd = 'responseEnd';

const reference = 'displayads-horseshoe.reference';
const init = 'displayads-horseshoe.init';
const load = 'displayads-horseshoe.load';
const loadTags = 'displayads-horseshoe.loadTags';
const loadTag = 'displayads-horseshoe.loadTag';
const available = 'displayads-horseshoe.adAvailable';
const refresh = 'displayads-horseshoe.refresh';
const setPageOpts = 'displayads-horseshoe.setPageOpts';
const filterUpdated = 'displayads-horseshoe.filterUpdated';
const pageLoaded = 'displayads-horseshoe.pageLoaded';

const timeToResponseEnd = 'displayads-horseshoe.timeToResponseEnd';
const timeToLoad = 'displayads-horseshoe.timeToLoad';
const timeToTagsLoaded = 'displayads-horseshoe.timeToTagsLoaded';
const timeToTagLoaded = 'displayads-horseshoe.timeToTagLoaded';
const timeToAdAvailable = 'displayads-horseshoe.timeToAdAvailable';
const timeToBannerRefresh = 'displayads-horseshoe.timeToBannerRefresh';
const timeToSetPageOpts = 'displayads-horseshoe.timeToSetPageOpts';

if (typeof window !== 'undefined') {
    window.FINN = window.FINN || ({} as Finn);
    window.FINN.banner = window.FINN.banner || ({} as BannerGlobal);
    window.FINN.banner.performance = window.FINN.banner.performance || {
        entries: [],
        updated: false,
    };
}

export function markHorseshoeLoaded() {
    if ('performance' in window) {
        performance.mark(load);
    }
}

export function markHorseshoeInitiated() {
    if ('performance' in window) {
        performance.mark(init);
        performance.mark(reference);

        performance.measure(timeToResponseEnd, init, responseEnd);
        performance.measure(timeToLoad, init, load);

        const responseEndEntry = formatEntry(timeToResponseEnd, responseEnd);
        if (responseEndEntry) {
            window.FINN.banner.performance.entries.push(responseEndEntry);
        }

        const loadEntry = formatEntry(timeToLoad, 'load');
        if (loadEntry) {
            window.FINN.banner.performance.entries.push(loadEntry);
        }
        window.FINN.banner.performance.entries.push({
            event: 'init',
            'time (ms)': 0,
        });
        outputPerformanceWhenIdle();
    }
}

export function markTagLoaded(name: string) {
    if ('performance' in window) {
        performance.mark(loadTag);
        performance.measure(timeToTagLoaded, reference, loadTag);
        performance.clearMarks(loadTag);

        const entry = formatEntry(timeToTagLoaded, `tagLoaded - ${name}`);
        if (entry) {
            window.FINN.banner.performance.entries.push(entry);
        }
    }
}

export function markTagsLoaded() {
    if ('performance' in window) {
        performance.mark(loadTags);
        performance.measure(timeToTagsLoaded, reference, loadTags);

        const entry = formatEntry(timeToTagsLoaded, 'tagsLoaded');
        if (entry) {
            window.FINN.banner.performance.entries.push(entry);
        }
    }
}

export function markFilterUpdated() {
    if ('performance' in window) {
        outputPerformance();
        performance.mark(filterUpdated);
        performance.mark(reference);
        window.FINN.banner.performance.updated = true;
        window.FINN.banner.performance.entries.push({
            event: 'filterUpdate',
            'time (ms)': 0,
        });
        outputPerformanceWhenIdle();
    }
}

export function markPageLoaded() {
    if ('performance' in window) {
        outputPerformance();
        performance.mark(pageLoaded);
        performance.mark(reference);
        window.FINN.banner.performance.updated = true;
        window.FINN.banner.performance.entries.push({
            event: 'pageLoaded',
            'time (ms)': 0,
        });
        outputPerformanceWhenIdle();
    }
}

export function markAdAvailable(name: string) {
    if ('performance' in window) {
        performance.mark(available);
        performance.measure(timeToAdAvailable, reference, available);
        performance.clearMarks(available);

        const entry = formatEntry(timeToAdAvailable, `adsAvailable - ${name}`);
        if (entry) {
            window.FINN.banner.performance.entries.push(entry);
        }
    }
}

export function markBannerRefresh() {
    if ('performance' in window) {
        performance.mark(refresh);
        performance.measure(timeToBannerRefresh, reference, refresh);
        performance.clearMarks(refresh);

        const entry = formatEntry(timeToBannerRefresh, 'refresh');
        if (entry) {
            window.FINN.banner.performance.entries.push(entry);
        }
    }
}

export function markSetPageOpts() {
    if ('performance' in window) {
        performance.mark(setPageOpts);
        performance.measure(timeToSetPageOpts, reference, setPageOpts);
        performance.clearMarks(setPageOpts);

        const entry = formatEntry(timeToSetPageOpts, 'setPageOpts');
        if (entry) {
            window.FINN.banner.performance.entries.push(entry);
        }
    }
}

function formatEntry(entry: string, displayName: string) {
    let output: PerformanceEntry | undefined;
    const entries = performance.getEntriesByName(entry);
    if (entries && entries.length) {
        const last = entries[entries.length - 1];
        output = {
            event: displayName,
            'time (ms)': last.duration,
        };
    }
    performance.clearMeasures(entry);
    return output;
}

function outputPerformance() {
    if (window.FINN.banner.performance.entries.length) {
        const title = window.FINN.banner.performance.updated
            ? 'displayads update performance'
            : 'displayads initial performance';
        diagnostics.group(title);
        diagnostics.table(window.FINN.banner.performance.entries);
        diagnostics.groupEnd();
        window.FINN.banner.performance.entries = [];
    }
}

const outputPerformanceWhenIdle = debounce(outputPerformance, 2000);
