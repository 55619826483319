import * as React from 'react';
import { AdObj } from '../../../shared/types/ast';
import { SlotOrDiv } from '../banner';

type Props = {
    adObj: AdObj;
    className?: string;
    style?: React.CSSProperties;
    placement?: 'right' | 'left';
    slotAd?: boolean;
};

export default class TransparencyButton extends React.Component<Props> {
    element: HTMLElement | null | undefined;

    componentDidMount() {
        if (this.element) {
            const clickEventListener = (event: MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
            };

            this.element.addEventListener('click', clickEventListener);
        }
        if (this.element) {
            const mousedownEventListener = (event: MouseEvent) => {
                event.preventDefault();
                return false;
            };
            this.element.addEventListener('mousedown', mousedownEventListener);
        }
    }

    click() {
        if (this.element) {
            const button = this.element.firstElementChild;
            if (button && button instanceof HTMLElement) {
                button.click();
            }
        }
    }

    render() {
        const { adObj, placement, className, style, slotAd } = this.props;
        let defaultStyle = {};
        if (placement) {
            const cornerStyle = {
                position: 'absolute',
                height: '26px',
                width: '26px',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 2,
            };
            let placementStyle = {};
            if (placement === 'right') {
                placementStyle = {
                    top: '0px',
                    right: '0px',
                    borderRadius: '0 0 0 8px',
                };
            }
            if (placement === 'left') {
                placementStyle = {
                    top: '0px',
                    left: '0px',
                    borderRadius: '0 0 8px 0',
                };
            }
            defaultStyle = { ...cornerStyle, ...placementStyle };
        }
        return (
            <SlotOrDiv
                slotAd={slotAd}
                id={`${adObj.targetId}-button`}
                className={className}
                style={{ ...defaultStyle, ...style }}
                ref={(element) => (this.element = element)}
            />
        );
    }
}
