import { ADVT, CurrentAdvtState, Keywords } from '../shared/types/ast';
import { Filter, HorseshoeConfig } from '../shared/types/podlet';
import { dedupeKeywords } from './utils/page-opts';
import { getPodletConfigFromHtml } from './podlet/config';
import { displayAdsLog } from './utils/debug';
import { getCMPAdvertisingFromTcf } from './utils/external-id';

function ifWindowExists(callback: () => void) {
    if (typeof window !== 'undefined') {
        callback();
    }
}

function assureState() {
    ifWindowExists(() => {
        window.HORSESHOE_PODLET =
            window.HORSESHOE_PODLET ||
            ({
                state: {
                    pageOpts: {},
                    filter: {},
                    cycle: 0,
                    clientReady: false,
                } as CurrentAdvtState,
                config: getPodletConfigFromHtml(),
                ...window.HORSESHOE_PODLET,
            } as ADVT);
    });
}

export function setConfig(config: HorseshoeConfig) {
    assureState();
    ifWindowExists(() => {
        window.HORSESHOE_PODLET.config = config;
    });
}

export function getInitialConfig(): HorseshoeConfig {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.config;
    }
    return {} as HorseshoeConfig;
}

export function getState() {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.state;
    }
    return {} as ADVT;
}

export function updateState(state: Partial<CurrentAdvtState>) {
    assureState();
    if (typeof window !== 'undefined') {
        window.HORSESHOE_PODLET.state = {
            ...getState(),
            ...state,
        } as CurrentAdvtState;
    }
}

export function getInitialKeywords(): Keywords {
    const config = getInitialConfig();
    return config?.xandr?.pageOpts?.keywords || {};
}

export function getPageOptsFromState() {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.state.pageOpts;
    }
    return undefined;
}

export function setFilter(filter: Filter) {
    assureState();
    if (typeof window !== 'undefined') {
        window.HORSESHOE_PODLET.state.filter = filter;
    }
}

export function setClientReady(value: boolean) {
    assureState();
    if (typeof window !== 'undefined') {
        window.HORSESHOE_PODLET.state.clientReady = value;
    }
}

export function getClientReady(): boolean {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.state.clientReady;
    }
    return false;
}

export function getFilter(): Filter {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.state.filter;
    }
    return {} as Filter;
}

export function setKeywordsInPageOpts(keywords: Keywords) {
    assureState();
    if (typeof window !== 'undefined') {
        const dedupedKeywords = dedupeKeywords(
            window.HORSESHOE_PODLET.state.pageOpts.keywords,
            keywords,
        );
        displayAdsLog({
            msg: 'setKeywordsInPageOpts - dedupedKeywords',
            keywords,
            dedupedKeywords,
        });

        // Do this because our server does not set proper cmp value
        const keywordsWithSafetyCmpValue = {
            ...dedupedKeywords,
            'aa-sch-cmp_advertising': [getCMPAdvertisingFromTcf() || '0'],
        };
        window.HORSESHOE_PODLET.state.pageOpts = {
            ...window.HORSESHOE_PODLET.state.pageOpts,
            keywords: keywordsWithSafetyCmpValue,
        };
    }
}

export function getCycle(): number {
    assureState();
    if (typeof window !== 'undefined') {
        return window.HORSESHOE_PODLET.state.cycle;
    }
    return 0;
}
export function setCycle(cycle: number) {
    assureState();
    if (typeof window !== 'undefined') {
        window.HORSESHOE_PODLET.state.cycle = cycle;
    }
}
