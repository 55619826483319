import { getConfig } from '../../../../../config';
import * as React from 'react';
import HorizontalBlock from '../../../blocks/horizontal-block';
import VerticalBlock from '../../../blocks/vertical-block';
import SponsorHeader from '../../../components/sponsor-header';
import FabricAnchorCard from '../../../components/fabric-anchor-card';

const RealEstateSectionSingle = ({ contentMarketing }) => {
    const { deviceType } = getConfig();

    if (deviceType === 'mobile') {
        return (
            <>
                <SponsorHeader contentMarketing={contentMarketing} />
                <FabricAnchorCard contentMarketing={contentMarketing}>
                    <VerticalBlock
                        isMultipleAds={false}
                        noPadding={false}
                        contentMarketing={contentMarketing}
                    />
                </FabricAnchorCard>
            </>
        );
    }
    return (
        <FabricAnchorCard contentMarketing={contentMarketing}>
            <HorizontalBlock
                noPadding={false}
                contentMarketing={{
                    ...contentMarketing,
                    imagePosition: contentMarketing?.imagePosition || 'left',
                }}
            />
        </FabricAnchorCard>
    );
};

export default RealEstateSectionSingle;
