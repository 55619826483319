import * as React from 'react';
import { Props } from '../native';
import { useMemo, useRef } from 'react';
import { escapeContentMarketing } from '../../content-marketing/content-marketing-container';
import { getConfig, getFeatureStatus } from '../../../config';
import SponsorHeader from '../../content-marketing/components/sponsor-header';
import FabricAnchorCard from '../../content-marketing/components/fabric-anchor-card';
import ImageWithFocus from '../../content-marketing/components/image-with-focus';
import { Clickable } from '@warp-ds/react';
import { TransparencyButton } from '../../sub';
import { UNLEASH_FEATURE_NAMES } from '../../../../shared/unleash/feature-names';
import { textWithEllipse } from '../../../utils/string';

function asPx(value: number) {
    return `${value || 0}px`;
}

export default function NativeAdContent({ adObj, id }: Props) {
    const { native } = adObj;
    const { pageType, subvertical } = getConfig();
    const containerRef = useRef();
    const isObject = pageType === 'object';

    const contentMarketing = useMemo(() => {
        const switchXandrContentMarketingTitles = getFeatureStatus(
            UNLEASH_FEATURE_NAMES.switchXandrContentMarketingTitles,
        );
        const title = switchXandrContentMarketingTitles
            ? native.body
            : native.title;
        const text = switchXandrContentMarketingTitles
            ? native.title
            : native.body;

        return escapeContentMarketing({
            title: textWithEllipse(title),
            text: textWithEllipse(text),
            id: id,
            variantName: '',
            sponsor: native.sponsoredBy,
            width: asPx(native.image.width),
            imagePosition: 'top',
            href: native.clickUrl,
            image: {
                id: '',
                url: native?.image?.url || '',
                height: native?.image?.height,
                width: native?.image?.width,
            },
            icon: {
                url: native?.icon?.url || native?.iconImgUrl,
                id: '',
                height: asPx(native?.icon?.height),
                width: asPx(native?.icon?.width),
            },
        });
    }, [native, id]);

    const accessibilityId = `cm-title-${contentMarketing.id}`;

    return (
        <article
            ref={containerRef}
            id={contentMarketing.id}
            className={`col-span-full h-full w-full relative ${
                subvertical === 'prepackage' ? 'm-20' : ''
            }`}
        >
            <SponsorHeader
                contentMarketing={contentMarketing}
                className={'pr-0'}
            />
            <FabricAnchorCard contentMarketing={contentMarketing}>
                <div
                    data-testid={'cm-vertical-block'}
                    className={'flex flex-col'}
                >
                    <ImageWithFocus
                        hasImageRadius={false}
                        alt="Annonsebilde"
                        contentMarketing={contentMarketing}
                        isMultipleAds={false}
                    >
                        <div className={'absolute top-0 right-0'}>
                            <TransparencyButton
                                placement={'right'}
                                adObj={adObj}
                            />
                        </div>
                    </ImageWithFocus>
                    <div
                        className={`bg-white ${
                            isObject ? 'px-8 py-16' : 'p-16'
                        }`}
                    >
                        <p className={`text-14 sm:text-16 mb-0`}>
                            {contentMarketing.text}
                        </p>
                        <h2
                            className={`${
                                isObject ? 'text-xl' : 'text-l'
                            } mb-0 font-normal`}
                            id={accessibilityId}
                        >
                            <Clickable
                                href={contentMarketing.href}
                                rel="noreferrer"
                                target="_blank"
                                title={contentMarketing.title}
                                id={accessibilityId}
                                className={
                                    'da-no-underline link link--dark sf-ad-link s-text hover:no-underline focus:no-underline focus-ring'
                                }
                            >
                                {contentMarketing.title}
                            </Clickable>
                        </h2>
                    </div>
                </div>
            </FabricAnchorCard>
        </article>
    );
}
