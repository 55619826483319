import * as React from 'react';
import { Native } from '../../../shared/types/ast';

type Props = {
    native: Native;
    style?: React.CSSProperties;
};

export default function Badge(props: Props) {
    const { native, style } = props;
    const text = native.desc2 === 'content' ? 'Annonsørinnhold' : 'Annonse';

    return (
        <div style={style}>
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: '100%',
                }}
            >
                <div
                    style={{
                        border: '1px solid rgb(195, 204, 217)',
                        marginRight: '4px',
                        borderRadius: '4px',
                        padding: '0px 3px',
                        display: 'inline-table',
                        float: 'left',
                    }}
                >
                    {text}
                </div>
                <div
                    style={{
                        marginTop: '1px',
                        float: 'left',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '50ch',
                    }}
                >
                    {native.sponsoredBy}
                </div>
            </div>
        </div>
    );
}
