import { useEffect, useState } from 'react';

import { MessageBus } from '@podium/browser';

const messageBus = new MessageBus();

function getInitialTargetId(placementId: string) {
    const existingCycle = messageBus.peek('banner', 'refresh');

    if (existingCycle) {
        const cycle = existingCycle.payload;

        return `${placementId}${cycle}`;
    }
    return placementId;
}

export default function useCycleAd(placementId: string): string {
    const [placementIdCycle, setPlacementIdCycle] = useState<string>(() =>
        getInitialTargetId(placementId),
    );

    useEffect(() => {
        const callback = (event) => {
            setPlacementIdCycle(placementId + event.payload);
        };
        messageBus.subscribe('banner', 'refresh', callback);
        return () => {
            messageBus.unsubscribe('banner', 'refresh', callback);
        };
    }, [placementId]);

    return placementIdCycle;
}
