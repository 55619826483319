import * as React from 'react';
import { TImprovedContentMarketing } from '../../../../shared/types/contentMarketing';

interface ISponsorImage {
    contentMarketing: TImprovedContentMarketing;
}
const LARGE_ICON = 'h-28';
const MID_ICON = 'h-20 md:h-24';
const SMALL_ICON = 'h-14 md:h-16';

export function calculateParentHeight(width: string, height: string) {
    const ratio = Number(width) / Number(height);

    if (ratio > 3.0) return SMALL_ICON;
    else if (ratio > 1.2) return MID_ICON;
    else return LARGE_ICON;
}

const SponsorImage = ({ contentMarketing }: ISponsorImage) => {
    return (
        <img
            alt={contentMarketing.sponsorData.name}
            className={calculateParentHeight(
                contentMarketing.sponsorData.width,
                contentMarketing.sponsorData.height,
            )}
            src={contentMarketing.sponsorData.url}
        />
    );
};

export default SponsorImage;
