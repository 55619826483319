import * as React from 'react';
import { getConfig } from '../../config';
import { ErrorBoundary } from '../error-boundary';
import {
    ContentMarketing as TContentMarketing,
    TImprovedContentMarketing,
} from '../../../shared/types/contentMarketing';
import { escape } from '../../utils/component/helpers';
import { PageType, Vertical } from '../../../shared/types/ast';
import VerticalSwitch from './verticals';
import { useEffect, useMemo } from 'react';
import { events, messageBus } from '@schibsted-nmp/advertising-events';
import { ContentMarketingElement } from './content-marketing-element';

type Props = {
    id: string;
    pageType?: PageType;
    vertical?: Vertical;
    contentMarketing?: TContentMarketing[];
};

export function escapeContentMarketing(
    cm: TContentMarketing,
): TImprovedContentMarketing {
    return {
        ...cm,
        title: escape(cm.title),
        href: escape(cm.href),
        text: escape(cm.text),
        textDesktop: escape(cm.textDesktop),
        sponsorData: {
            ...cm.icon,
            url: escape(cm.icon.url),
            name: escape(cm.sponsor),
        },
        imageData: {
            ...cm.image,
            url: escape(cm.image.url),
        },
    };
}

function ContentMarketingContainer(props: Props) {
    const {
        vertical: _vertical,
        contentMarketing: _contentMarketing,
        pageType: _pageType,
    } = getConfig();

    const {
        vertical = _vertical,
        contentMarketing = _contentMarketing,
        pageType = _pageType,
    } = props;

    useEffect(() => {
        messageBus.publish(
            events.PODLET.channel,
            events.PODLET.AD_STYLES.topic,
            events.PODLET.AD_STYLES.sendPayload({
                className: 'content-marketing-ad loaded',
                containerId: props.id + '--container',
            }),
        );
    }, [props.id]);

    const contentMarketingList = useMemo(() => {
        if (Array.isArray(contentMarketing)) {
            return contentMarketing.map((cm) => escapeContentMarketing(cm));
        } else return [];
    }, [contentMarketing]);

    if (contentMarketingList.length === 1) {
        const contentMarketing = contentMarketingList[0];

        return (
            <ContentMarketingElement
                placementId={props.id}
                contentMarketing={contentMarketing}
                isMultipleAds={false}
            >
                <VerticalSwitch
                    vertical={vertical}
                    contentMarketing={contentMarketing}
                    pageType={pageType}
                />
            </ContentMarketingElement>
        );
    } else if (contentMarketingList.length > 1) {
        return (
            <VerticalSwitch
                vertical={vertical}
                pageType={pageType}
                contentMarketingList={contentMarketingList}
                placementId={props.id}
            />
        );
    } else return null;
}

const ContentMarketingContainerWithErrorBoundary = (props: Props) => (
    <ErrorBoundary fallback={null}>
        <ContentMarketingContainer {...props} />
    </ErrorBoundary>
);

export default ContentMarketingContainerWithErrorBoundary;
