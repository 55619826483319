import * as React from 'react';
import {
    ContentMarketingWebViewData,
    TImprovedContentMarketing,
} from '../../../../shared/types/contentMarketing';

type Props = {
    contentMarketing: TImprovedContentMarketing;
    children: any;
    className?: string;
    webViewData?: ContentMarketingWebViewData;
};
const FabricAnchorCard = ({ children, webViewData, className = '' }: Props) => {
    const { isDarkMode } = webViewData || {};
    return (
        <div
            className={`${className} cm--card ${
                isDarkMode ? 'rounded-b-8' : 'rounded-8'
            } relative shadow-s hover:shadow-m overflow-hidden cursor-pointer s-bg hover:s-bg-selected w-full`}
        >
            {children}
        </div>
    );
};

export default FabricAnchorCard;

export const NonCardAnchorWrapper = ({ children }: Props) => {
    return (
        <div
            className={`cm--card relative w-full overflow-hidden transition-all rounded-8 hover:bg-aqua-50 hover:s-bg-selected-hover shadow-s p-8`}
        >
            {children}
        </div>
    );
};
