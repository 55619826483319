import { HorseshoeConfig } from '../../shared/types/podlet';

function isEmpty(input: string | null) {
    if (input && typeof input === 'string') {
        const trimmed = input.trim();
        if (trimmed.length) {
            return false;
        }
    }
    return true;
}

function getScriptElementData(id: string) {
    if (typeof document !== 'undefined') {
        const appNexusScriptElement = document.getElementById(id);
        if (appNexusScriptElement) {
            const { textContent } = appNexusScriptElement;
            if (textContent && isEmpty(textContent) === false) {
                try {
                    return JSON.parse(textContent);
                } catch (error) {
                    console.error(`Error in parsing ${id}`, error);
                }
            }
        }
    }

    return {};
}

export function getPodletConfigFromHtml() {
    return getScriptElementData('horseshoe-config') as HorseshoeConfig;
}

export function pageUsesRecomPodlet(config: HorseshoeConfig): boolean {
    const pageType = config?.pageType;
    const vertical = config?.vertical;
    return (
        pageType === 'front' ||
        pageType === 'object' ||
        (pageType === 'section' && vertical === 'bap')
    );
}
