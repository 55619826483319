import TransparencyButton from '../../sub/transparencyButton';
import * as React from 'react';

export const NativeTransparencyButton = ({
    adObj,
    className = '',
    slotAd,
}: {
    adObj: any;
    className?: string;
    slotAd?: boolean;
}) => {
    return (
        <div
            className={`order-first text-12 absolute top-0 right-0 z-1 ${className}`}
        >
            <TransparencyButton
                adObj={adObj}
                style={{
                    filter: '',
                }}
                placement="right"
                slotAd={slotAd}
            />
        </div>
    );
};
